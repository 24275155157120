<template>
    <v-menu
        v-model="isMenuOpen"
        :position-x="x"
        :position-y="y"
        absolute
        :close-on-click="false"
        :close-on-content-click="false"
    >
        <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="handleMenuClick(item)"
            >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ContextMenuDocument",
    data() {
        return {
            x: 0,
            y: 0,
            isMenuOpen: false,
            file: {},
            items: [
                {title: this.$t('sent'), action: 'sent'},
                {title: this.$t('open'), action: 'open'},
                {title: this.$t('download'), action: 'download'},
            ],
        };
    },
    methods: {
        openMenu(event, file) {
            event.preventDefault();
            this.file = file;
            this.x = event.clientX;
            this.y = event.clientY;
            this.isMenuOpen = true;
        },
        closeMenu() {
            this.file = {};
            this.isMenuOpen = false;
        },
        handleMenuClick(item) {
            this.$emit("menu-item-click", item, this.file);
            this.closeMenu();
        },
        handleOutsideClick(event) {
            if (!event.target.closest(".v-menu__content")) {
                this.closeMenu();
            }
        }
    },
    mounted() {
        document.addEventListener("click", this.handleOutsideClick);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleOutsideClick);
    },


};
</script>
