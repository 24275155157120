<template>
    <div>
        <!-- Элемент, на котором срабатывает контекстное меню -->
        <v-list-item-title class="cursor-pointer" v-text="file.name"  @click="$emit('open-file', file)"  @contextmenu="openContextMenu($event, file)"></v-list-item-title>
        <ContextMenuDocument
            ref="contextMenuDocument"
            @menu-item-click="menuItemClick"
        ></ContextMenuDocument>
    </div>
</template>

<script>
import ContextMenuDocument from "@/components/ContextMenuDocument.vue";
export default {
    name: "ContextMenuFile",
    components: {
        ContextMenuDocument
    },
    props: {
        file: {
            type: Object,
            required: true
        }
    },
    methods: {
        openContextMenu(event, file) {
            this.$refs.contextMenuDocument.openMenu(event, file);
        },
        menuItemClick(item, file){
            if (item.action === 'sent')
            {
                this.$emit("add-document-form", file);
            }
            else if (item.action === 'download')
            {
                this.downloadFileByURL(file)
            }
            else if (item.action === 'open')
            {
                this.$emit('open-file', file);
            }
        }
    }

}

</script>

<style scoped>

</style>
