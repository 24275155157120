<template>
    <PageLayout
        @drop.prevent="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragend.prevent="dragover = false"
    >
        <template>
            <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-row>
                    <v-col cols="12">
                        <v-row class="flex-nowrap">
                            <v-col cols="12">
                                <v-btn v-if="false" :to="{name: 'task'}">
                                    <v-icon color="primary">mdi-less-than</v-icon>
                                </v-btn>
                                <span :disabled="(author.id !== $auth.user().id)" v-if="!show_title">
                                    <h1 v-if="title && title !== 'null'">{{ number }}. {{ title }}</h1>
                                    <h1 v-else class="client_no_text">{{ number }}. {{ $t('enter_text') }}</h1>
                                </span>
                                <span v-else>
                                    <ValidationProvider ref="title" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-text-field
                                            autofocus
                                            @focusout="saveField('title')"
                                            v-model="title" type="text" :error-messages="errors"
                                            :disabled="loading"
                                            :label="$t('enter_text')"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </span>
                                <v-icon color="red" size="24" class="mb-2" v-if="is_urgent && (task_status !== 3 && task_status !== 5)">
                                    mdi-fire
                                </v-icon>
                                <v-icon :disabled="userBlock" v-if="!loadingTask && !show_title" :color="favorite ? 'primary' : ''" @click="favoriteUpdate()" size="20" class="mb-2">{{ favorite ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
                                <v-icon :disabled="userBlock" size="18" @click="share" v-if="isShareAvailable" class="mb-2 ml-2">mdi-share-variant-outline</v-icon>
                                <v-icon :disabled="userBlock || closed" v-if="!loadingTask && !show_title && ((author.id === $auth.user().id) || can(['administrator', 'director', 'general_manager']))" @click="show_title=true" size="20" class="mb-2 ml-2" :title="$t('edit')">mdi-pencil mdi-18px</v-icon>
                                <v-icon :disabled="userBlock || loading || closed" v-if="!loadingTask && !show_title" @click="translation(title)" size="20" class="mb-2 ml-2" :title="$t('translate')">mdi-google-translate</v-icon>
                                <v-icon :disabled="userBlock || loading" v-if="(edit_task_type === 2)" @click="approvalSheet" size="20" class="mb-2 ml-2" :title="$t('approval_sheet')">mdi-clipboard-list-outline</v-icon>
                                <v-icon @click="back('task')" size="20" class="mb-2 ml-2" :title="$t('back')">mdi-arrow-left</v-icon>

                                <v-btn
                                    v-if="is_responsible && userBlock && extension_button"
                                    class="my-0 py-0 ml-2 mb-2"
                                    color="red"
                                    dark
                                    @click="dialogUnlock=true"
                                    elevation="1">{{ $t('unlock') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="extension_form">
                    <v-col cols="12" v-if="is_overdue ? true : false">
                        <v-card>
                            <v-card-title>
                                <span class="font-weight-light">{{ $t('reason_why_we_didnt_meet_deadline') }}</span>
                            </v-card-title>
                            <v-card-text class="text-h5 font-weight-light">
                                "{{ unlock_text }}"
                            </v-card-text>
                            <v-card-title class="client-detail__info">
                                <span class="font-weight-light">{{ $t('requests_an_extension') }}</span>
                                <ValidationProvider ref="extension_date" rules="required|min:1" v-slot="{ errors, valid }">
                                    <datetime-picker
                                        v-model="extension_date" validator-name="extension_date"
                                        :error="!valid"
                                        dark
                                        validator-rules="required|min:1"
                                        format="24hr"
                                        :label="$t('change_deadline')" :locale="lang"
                                        :min-date-time="$moment().format('YYYY-MM-DD')"
                                        :timezone="$auth.user().timezone" timeFormat="HH:mm"
                                        readonly
                                        hide-details
                                        :error-messages="errors"
                                        :disabled="(author.id !== $auth.user().id) || loading || closed"
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 ml-2 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    ></datetime-picker>
                                </ValidationProvider>
                                <v-btn
                                    v-if="(author.id === $auth.user().id)"
                                    :disabled="invalid || loading"
                                    :loading="loading"
                                    dark
                                    small
                                    @click="unlockTask()"
                                    color="red" class="infinity_button ml-2 "
                                >{{ $t('unlock') }}
                                </v-btn>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-row class="border_bottom_6" :style="'border-color: '+status_color+' !important;'">
                                    <v-col cols="12" sm="3" v-if="author">
                                        <User :item="author" :disabled="closed" @click="editAuthorDialog()">
                                            <template v-slot:position>
                                                <span>{{ $t('director') }}</span>
                                            </template>
                                        </User>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-if="responsible && responsible[0] !== null && responsible[0] !== undefined && responsible[0].name !== null">
                                        <User :disabled="closed" :item="responsible[0]" @click="dialogAddParticipant = true">
                                            <template v-slot:position>
                                                <span>{{ $t('responsible') }}</span>
                                            </template>
                                        </User>
                                    </v-col>
                                    <v-col cols="12" :sm="deadline ? 2 : 3" v-if="created_at">
                                        <v-list class="background_none py-0">
                                            <v-list-item class="mx-0 px-0">
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="$t('date_created')"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="$moment(created_at).format('DD.MM.YYYY HH:mm')"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="2" v-if="deadline">
                                        <v-list :disabled="userBlock || closed" class="background_none py-0">
                                            <v-list-item class="mx-0 px-0" @click="is_author ? openDialogDate() : (is_responsible ? requestChangeDeadline() : '')">
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="$t('deadline')"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="$moment(deadline).format('DD.MM.YYYY HH:mm')"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" :sm="deadline ? 2 : 3" v-if="status_color">
                                        <v-list class="background_none py-0">
                                            <v-list-item class="mx-0 px-0">
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="$t('status')"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="status_name" :style="'color: '+status_color+' !important;'"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row v-if="request_change_deadline && request_change_deadline.id && is_responsible">
                                    <v-col cols="12" class="request_change_deadline">
                                        <div>
                                            {{$t('deadline_has_been_requested_to_be_postponed_to', {date:request_change_deadline.date})}}
                                        </div>
                                        <div class="mt-2">
                                            {{$t('reason')}}: {{request_change_deadline.reason}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-if="request_change_deadline && request_change_deadline.id && is_author">
                                    <v-col cols="12" class="request_change_deadline">
                                        <div>
                                            {{$t('asks_to_move_deadline', {name:request_change_deadline.admin.name, date:request_change_deadline.date})}}
                                        </div>
                                        <div class="mt-2">
                                            <b>{{$t('reason')}}: {{request_change_deadline.reason}}</b>
                                        </div>
                                        <div class="mt-4">
                                            <v-btn
                                                :disabled="closed"
                                                color="primary"
                                                small
                                                @click="acceptedRequestChangeDeadline(true)"
                                            >
                                                {{$t('accept')}}
                                            </v-btn>
                                            <v-btn
                                                :disabled="closed"
                                                text
                                                small
                                                color="primary"
                                                @click="acceptedRequestChangeDeadline(false)"
                                            >
                                                {{$t('reject')}}
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <!-- description -->
                                    <v-col cols="12">
                                        <div class="description_title mb-2">{{ $t('task_description') }}</div>
                                        <div v-if="!text_task_edit">
                                            <v-hover v-slot="{ hover }">
                                                <div v-if="text_task !== '' && text_task !== 'null' && text_task !== null" @click="text_task_edit = true" class="description_text cursor_pointer">
                                                    {{ text_task }}
                                                    <v-btn v-if="(author.id === $auth.user().id) && hover"
                                                           icon
                                                           x-small
                                                           @click="text_task_edit = true"
                                                           :disabled="userBlock || closed"
                                                    >
                                                        <v-icon color="primary">
                                                            mdi-pencil mdi-18px
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                                <div v-else>
                                                    <span v-if="!userBlock" :disabled="closed" class="add_text" @click="text_task_edit = true">{{ $t("add_text") }}</span>
                                                </div>

                                            </v-hover>
                                        </div>
                                        <div v-if="text_task_edit">
                                            <v-card elevation="0" class="m-0 p-0">
                                                <v-card-text class="my-0 py-0 mx-0 px-0">
                                                    <v-textarea
                                                        v-model="text_task"
                                                        type="text"
                                                        class="input_textarea"
                                                        auto-grow
                                                        :disabled="loading"
                                                        no-details
                                                        outlined
                                                        hide-details
                                                    ></v-textarea>
                                                    <v-card-actions class="px-0 mx-0">
                                                        <v-btn
                                                            :disabled="userBlock || closed"
                                                            color="primary"
                                                            class="infinity_button"
                                                            @click="(author.id === $auth.user().id || can(['administrator', 'director', 'general_manager'])) ? TaskTextUpdate() : ''"
                                                        >
                                                            {{ $t('save') }}
                                                        </v-btn>
                                                        <v-btn
                                                            :disabled="userBlock"
                                                            text
                                                            class="infinity_button_text px-0"
                                                            @click="text_task_edit = false"
                                                        >
                                                            {{ $t('cancel') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>

                                    <!-- documents -->
                                    <v-col class="task-documents" v-if="task_documents.length > 0">
                                        <div class="task-document bordered" v-for="file in task_documents" :key="file.document.id + '_' + Date.now()">
                                            <div class="task-document__desc approval-sheet__item">
                                                <div class="task-document__icon" @click="downloadFileMessage(file.document.url, file.document.name)">
                                                    <v-img
                                                        max-width="45"
                                                        max-height="45"
                                                        :alt="`${file.document.name}`"
                                                        :src="file.document.url"
                                                        v-if="mimeTypeImage(file.document.type)"
                                                    ></v-img>
                                                    <v-icon
                                                        x-large
                                                        size="10"
                                                        color="primary"
                                                        v-else
                                                    >
                                                        mdi-file-document-outline
                                                    </v-icon>
                                                </div>
                                                <div class="task-document__text" >
                                                    <div class="task-document__name cursor-pointer" @click="openFile(file.document)" @contextmenu.prevent="downloadFile(file.document)" v-text="file.document.name"></div>
                                                    <div class="task-document__size" v-text="(file.document.size / 1000).toFixed(1) + ' KB'"></div>
                                                    <div class="task-document__author" v-text="file.author.name"></div>
                                                </div>
                                            </div>

                                            <div class="task-document__approval-actions approval__actions" v-if="file.answered === null && is_aligner && task_status === 2">
                                                <v-btn :disabled="userBlock || loading || closed" class="ma-2" color="primary" @click="task_document_id=file.document.id;addStatusApprovalFile('accept')">{{ $t('agreed') }}</v-btn>
                                                <v-btn :disabled="userBlock || loading || closed" class="ma-2 mr-0" @click="task_document_id=file.document.id;dialogStatusApprovalFile = true">{{ $t('not_agreed') }}</v-btn>
                                            </div>

                                            <div class="task-document__approval approval">
                                                <div class="task-document__approval-info approval__info" v-if="file.answered === true || file.answered === false">
                                                    <div>
                                                        <v-icon color="green">mdi-check-circle-outline</v-icon>
                                                        {{ $t('agreed_count', {'count': file.accepted}) }}
                                                    </div>
                                                    <div>
                                                        <v-icon color="red">mdi-close-circle-outline</v-icon>
                                                        {{ $t('rejected', {'count': file.rejected}) }}
                                                    </div>
                                                    <div>
                                                        <v-icon color="grey">mdi-alert-circle-outline</v-icon>
                                                        {{ $t('did_not_make_decision', {'count': (file.not_accepted)}) }}
                                                    </div>
                                                </div>

                                                <div class="task-document__approval-users approval__users" v-if="file.agreed.length">
                                                    <v-list-item
                                                        class="mx-0 px-0"
                                                        v-for="aligner in file.agreed"
                                                        :key="aligner.author.name + aligner.author.id"
                                                    >
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="aligner.author.photo !== '/img/avatar.png'" :src="aligner.author.photo" :alt="aligner.author.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="aligner.author.name"></v-list-item-title>
                                                            <v-list-item-subtitle class="chat_text mr-1" v-if="(aligner.reason && !aligner.agreed)" v-text="$t('rejected_text', {reason:aligner.reason})"></v-list-item-subtitle>
                                                            <v-list-item-subtitle v-else-if="(aligner.agreed)" v-text="$t('agreed_all')"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row v-if="alignerItems && alignerItems.length > 0">
                                    <v-col cols="12">
                                        <div class="approval">
                                            <div class="approval__info">
                                                <div>
                                                    <v-icon color="green">mdi-check-circle-outline</v-icon>
                                                    {{ $t('agreed_count', {'count': accepted}) }}
                                                </div>
                                                <div>
                                                    <v-icon color="red">mdi-close-circle-outline</v-icon>
                                                    {{ $t('rejected', {'count': rejected}) }}
                                                </div>
                                                <div>
                                                    <v-icon color="grey">mdi-alert-circle-outline</v-icon>
                                                    {{ $t('did_not_make_decision', {'count': not_accepted}) }}
                                                </div>
                                            </div>
                                            <div class="approval__users">
                                                <v-list class="my-0 py-0">
                                                    <v-list-item
                                                        class="mx-0 px-0"
                                                        v-for="aligner in alignerItems"
                                                        :key="aligner.name + aligner.id"
                                                    >
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="aligner.photo !== '/img/avatar.png'" :src="aligner.photo" :alt="aligner.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="aligner.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-if="(aligner.reason && !aligner.agreed)" v-text="$t('rejected_text',{reason:aligner.reason})"></v-list-item-subtitle>
                                                            <v-list-item-subtitle v-else-if="(aligner.agreed)" v-text="$t('agreed_all')"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row v-if="dangerous_cargo && edit_task_type === 3">
                                    <v-col>
                                        <div class="description_title" v-if="show_special_text || (special_text !== 'null' && special_text !== null)">{{ $t('special_text') }}</div>
                                        <div>
                                            <template @click="showInput('special_text')" v-if="!show_special_text">
                                                <span class="description_text cursor_pointer" v-if="special_text && (special_text !== 'null' && special_text !== null)">{{ special_text }}</span>
                                                <span v-else class="client_no_text">{{ $t('special_text') }}</span>
                                            </template>
                                            <template v-else>
                                                <ValidationProvider ref="special_text" rules="min:1|max:65535" v-slot="{ errors, valid }">
                                                    <v-textarea v-model="special_text" type="text" :error-messages="errors"
                                                                :disabled="loading" :label="$t('enter_text')" rows="5"
                                                                color="primary"
                                                                auto-grow
                                                                clearable outlined
                                                                hide-details
                                                                full-width
                                                                autofocus
                                                                @focusout="saveField('special_text')"
                                                                background-color="white lighten-2"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                    </v-textarea>
                                                </ValidationProvider>
                                            </template>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" v-if="!is_agreed && is_aligner && task_status === 2 && task_documents.length === 0">
                                        <v-btn :disabled="userBlock || loading" @click="addStatusApproval('accept')" class="infinity_button" color="green" dark>
                                            <v-icon class="pr-2">mdi-check-circle-outline</v-icon>
                                            {{ $t('agreed') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" @click="dialogStatusApproval=true" text plain outlined class="infinity_button_text ml-5">
                                            <v-icon class="pr-2" color="red">mdi-close-circle-outline</v-icon>
                                            {{ $t('not_agreed') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="task__actions buttons-block" cols="12" v-if="(task_status === 1 || task_status === 2 || task_status === 6) && is_executor">
                                        <v-btn :disabled="userBlock || loading" v-if="executor_task_status_id === 0" @click="editStatusExecutor('accept')" class="infinity_button" color="primary">
                                            {{ $t('accept') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading"   v-if="executor_task_status_id === 0"  @click="cancelDialogStatusExecutor('reject')" text plain class="infinity_button_text pl-1">
                                            {{ $t('reject') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading"  v-if="executor_task_status_id === 2"  @click="editStatusExecutor('done')" class="infinity_button" color="primary">
                                            {{ $t('done') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading"   v-if="executor_task_status_id === 2" @click="cancelDialogStatusExecutor('cancel')" text plain class="infinity_button_text">
                                            {{ $t('fail') }}
                                        </v-btn>
                                    </v-col>

                                    <v-col class="task__actions buttons-block" cols="12" v-if="is_responsible ||  can(['administrator', 'director', 'general_manager'])">
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 1" @click="editStatus('accept')" class="infinity_button" color="primary">
                                            {{ $t('accept') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || all_agreed === false" v-if="task_status === 2" @click="editStatus('done')" class="infinity_button" color="primary">
                                            {{ $t('done') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 1 || task_status === 2" @click="editStatus('cancel')" text plain class="infinity_button_text">
                                            {{ $t('fail') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 1 || task_status === 2" @click="editStatus('pause')" text plain class="infinity_button_text pl-1">
                                            <v-icon class="pr-1">mdi-motion-pause-outline</v-icon>
                                            {{ $t('pause') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 4" @click="editStatus('accept')" text plain class="infinity_button_text">
                                            <v-icon class="pr-1">mdi-motion-play-outline</v-icon>
                                            {{ $t('restore') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="canDelegate" @click="dialogDelegate = true" text plain class="infinity_button_text">
                                            <v-icon class="pr-1">mdi-redo</v-icon>
                                            {{ $t('delegate') }}
                                        </v-btn>
                                    </v-col>


                                    <v-col class="task__actions buttons-block" cols="12" v-if="[7,6,3,5].includes(task_status) && (is_author || is_auditor || can(['administrator', 'director', 'general_manager']))">
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 7" @click="editStatus('complete')" class="infinity_button" color="primary">
                                            {{ $t('complete') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 6" @click="editStatus('approved')" class="infinity_button" color="primary">
                                            {{ $t('approved') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 6 || task_status === 7" @click="openStatusDeadline('assigned')" text plain class="infinity_button_text  mr-0 pr-0" color="blue">
                                            {{ $t('diet') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 6 || task_status === 7" @click="editStatus('cancel')" text plain class="infinity_button_text">
                                            {{ $t('fail') }}
                                        </v-btn>
                                        <v-btn :disabled="userBlock || loading" v-if="task_status === 3 || task_status === 5" @click="openStatusDeadline('assigned')" text plain class="infinity_button_text">
                                            {{ $t('resume') }}
                                        </v-btn>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <ResponsiveTabs tabsPerRow="3">
                            <ResponsiveTab>
                                <template v-slot:btn>
                                    <span>{{ $t('subtasks') }}{{ (totalSubTasks > 0 ? ': ' : '') }}</span>
                                    <span v-if="totalSubTasks > 0" class="text-secondary">{{ totalSubTasks }}</span>
                                </template>
                                <template v-slot:btn-after>
                                    <div class="tab__btns">
                                        <v-icon :disabled="userBlock || closed" color="secondary" @click="linkSubTask">
                                            mdi-link
                                        </v-icon>
                                        <v-icon :disabled="userBlock || closed" color="secondary" @click="addSubTask">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </template>
                                <template>
                                    <v-data-table
                                        :headers="subtask_headers"
                                        :items="subtaskItems"
                                        :options.sync="options"
                                        :page.sync="page"
                                        :items-per-page="perPage"
                                        :server-items-length="totalSubTasks"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDir"
                                        :loading="loading"
                                        :locale="lang"
                                        @page-count="pageCount = options.pageCount = Number($event)"
                                        :loading-text="$t('loading_please_wait')"
                                        hide-default-footer

                                        :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                        class="elevation-0"
                                        :item-class="rowClass"
                                    >

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.title="{ item }" class="text">
                                            <v-btn text plain @click="forceRerender" :to="'/task/' + item.uuid + '/show'"  class="cursor-pointer font_weight_600 button_link">
                                                {{ item.title }}
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.task_status="{ item }">
                                            <div>{{ item.task_status }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>

                                        <template v-slot:item.admin.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>

                                    </v-data-table>
                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab>
                                <template v-slot:btn>
                                    <span>{{ $t('deals') }}</span>
                                    <span class="text-secondary">
                                        {{ dealItems.length > 0 ? ': ' + dealItems.length : '' }}
                                    </span>
                                </template>
                                <template v-slot:btn-after>
                                    <div class="tab__btns">
                                        <v-icon :disabled="userBlock || closed" color="secondary" @click="linkDeal">
                                            mdi-link
                                        </v-icon>
                                        <v-icon :disabled="userBlock || closed" color="secondary" @click="addDeal">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </template>
                                <template>
                                    <v-card>
                                        <v-card-text class="px-6">
                                            <v-data-table
                                                :headers="deal_headers" :items="dealItems"

                                                :loading="loading" :locale="lang"
                                                :loading-text="$t('loading_please_wait')"
                                                hide-default-footer

                                                :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                class="elevation-0"
                                                :item-class="rowClass2"
                                            >
                                                <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                                    <thead>
                                                    <tr class="rowHeadClass Table5">
                                                        <th v-for="h in headers" :key="h.value" class="table_header">
                                                            <span>{{ h.text }}</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </template>

                                                <template v-slot:item.number="{ item }">
                                                    <div>
                                                        <v-btn text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link">
                                                            {{ item.number }}
                                                        </v-btn>
                                                    </div>
                                                </template>

                                                <template v-slot:item.deal_status="{ item }">
                                                    <div>{{ item.deal_status }}</div>
                                                </template>
                                                <template v-slot:item.created_at="{ item }">
                                                    <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                                </template>
                                                <template v-slot:item.admin="{ item }">
                                                    <div>
                                                        <v-list subheader two-line class="background_none py-0">
                                                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                                    <v-icon v-else color="primary" size="22">
                                                                        ${{ 'settingsIcon' }}
                                                                    </v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="py-0 title_subtitle">
                                                                    <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-text="item.admin.name"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </template>
                                                <template v-slot:item.active="{ item }">
                                                    <v-edit-dialog :return-value.sync="item.active" large persistent
                                                                   @save="activeDeal(item)" :save-text="$t('save')"
                                                                   :cancel-text="$t('cancel')">
                                                        <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no') }}</div>
                                                        <template v-slot:input>
                                                            <v-select v-model="item.active" :items="itemYN" item-text="name"
                                                                      item-value="id"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                      color="primary"
                                                                      class="pt-5 elevation-0 rounded-lg input_text"
                                                            ></v-select>
                                                        </template>
                                                    </v-edit-dialog>
                                                </template>

                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab>
                                <template v-slot:btn>
                                    {{ $t('cases') }}
                                </template>
                                <template>
                                    <calendar-case ref="calendarCase"></calendar-case>
                                </template>
                            </ResponsiveTab>

                            <ResponsiveTab v-for="tab in tabs" :key="tab.id" :title="tab.name">
                                <template>
                                    <FormTabField entity_type="uved" :entity_uuid="task_uuid" :key="tab.id" :tab="tab"  />
                                </template>
                            </ResponsiveTab>
                        </ResponsiveTabs>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <Messages entity="task" :entity_uuid="id" socket-name="TaskChat" @image-view="ImageView" @image-view-close="ImageViewClose" />
                    </v-col>
                </v-row>
            </ValidationObserver>

            <v-dialog
                v-if="canDelegate"
                v-model="dialogDelegate"
                width="500"
            >
                <v-card>
                    <ValidationObserver v-slot="{ invalid, validated, passes, validate }">
                        <v-card-title class="text-h5 grey lighten-2">
                            {{ $t('delegating') }}
                        </v-card-title>

                        <v-card-text>
                            <ValidationProvider ref="delegatingToUsers" rules="required">
                                <UserSelection
                                    id="user-for-delegate"
                                    v-model="delegatingToUsers"
                                    multiple
                                    :label="$t('enter_user_name_delegate')"
                                />
                            </ValidationProvider>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <Btn
                                color="default"
                                text
                                @click="dialogDelegate = false"
                            >
                                {{ $t('cancel') }}
                            </Btn>
                            <Btn
                                :disabled="invalid"
                                @click="onDelegate"
                            >
                                {{ $t('delegate') }}
                            </Btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="accompanying_documents_dialog"
                scrollable
                persistent
                transition="dialog-bottom-transition"
                max-width="500px"
                class="dialog_body"

            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('send_files', {'count': accompanying_documents_preview.length}) }}</span>
                        </div>
                        <div>
                            <v-icon @click="accompanying_documents_dialog = false;accompanying_documents_preview=[];accompanying_documents=[]">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text style="height: 300px;" class="pa-0">
                        <v-list subheader>
                            <v-list-item v-for="document in accompanying_documents_preview" :key="document.id">
                                <v-list-item-icon>
                                    <v-img
                                        max-width="90"
                                        :alt="`${document.name}`"
                                        :src="document.url"
                                        max-height="60"
                                    ></v-img>
                                    <v-icon
                                        x-large
                                        v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title v-text="document.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon class="d-flex align-self-center mt-2">
                                    <v-btn
                                        icon
                                        @click="deleteAccompanyingDocuments(document)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>

                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="saveTaskFile">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogStatusApproval"
                class="dialog_body"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0"
                    >{{ $t('reason_for_rejecting_task') }}
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                                v-slot="{ errors, valid }">
                                <v-textarea
                                    :label="$t('write_reason_for_the_rejection')"
                                    v-model="reject_text"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    rows="8"
                                    color="primary"
                                    auto-grow
                                    clearable outlined

                                    full-width
                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                >
                                </v-textarea>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions class="justify-end pt-0">
                        <v-btn
                            text
                            @click="dialogStatusApproval = false"
                        >{{ $t('close') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="red"
                            @click="addStatusApproval('reject')"
                        >{{ $t('reject') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogStatusApprovalFile"
                class="dialog_body"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0"
                    >{{ $t('reason_for_rejecting_file') }}
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                                v-slot="{ errors, valid }">
                                <v-textarea
                                    :label="$t('write_reason_for_the_rejection')"
                                    v-model="reject_text"
                                    :error-messages="errors"
                                    :disabled="loading"
                                    rows="8"
                                    color="primary"
                                    auto-grow
                                    clearable outlined

                                    full-width
                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                >
                                </v-textarea>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions class="justify-end pt-0">
                        <v-btn
                            text
                            @click="dialogStatusApprovalFile = false"
                        >{{ $t('close') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="red"
                            @click="addStatusApprovalFile('reject')"
                        >{{ $t('reject') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogLinkDeal"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('link_deal') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogLinkDeal = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <v-col class="py-0 pt-5" cols="12" sm="12">
                            <ValidationProvider ref="deal" v-slot="{ errors, valid }">
                                <div class="input_label">{{ $t('deal') }}</div>
                                <v-combobox v-model="deal" :items="dealAddItems"
                                            :error="!valid"
                                            :search-input.sync="dealSearching" hide-selected
                                            item-text="name" item-value="id" :loading="loadingDeals"
                                            :label="$t('deal')" @click:clear="dealAddItems=[]"
                                            @click="clearDeals" @input="clearEmptyDealItems(deal)"
                                            multiple return-object autocomplete="off"
                                            hide-details
                                            :error-messages="errors"
                                            :disabled="loading"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                            clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                    <span class="search_list">
                                        <span>{{ item.number + ' ' + ((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '') }}</span>
                                    </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip v-if="item === Object(item)"
                                                class="input_chip"
                                                v-bind="attrs" :input-value="selected"
                                                @click:close="parent.selectItem(item)"
                                                :color="item.deal_status_color"
                                                :title="item.deal_status"
                                                link close>
                                            <span>{{ item.number }} {{ ((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '') }}</span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ dealSearching ? $t('nothing_found_by', {'search': dealSearching}) : $t('nothing_found_name') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="addTaskToDeal()">
                            {{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                v-model="dialogAddParticipants"
                v-if="dialogAddParticipants"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('edit_participants') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogAddParticipants = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider ref="responsibleAdd" rules="required|min:1" v-slot="{ errors, valid }">
                                    <UserSelection
                                        multiple
                                        v-model="responsibleAdd"
                                        id="responsible"
                                        :label="$t('responsible')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                    />
                                </ValidationProvider>
                            </v-col>



                            <v-col class="py-0" cols="12" v-if="edit_task_type !== 2">
                                <ValidationProvider ref="executorsAdd" rules="min:1" v-slot="{ errors, valid }">
                                    <UserSelection
                                        multiple
                                        v-model="executorsAdd"
                                        id="executor"
                                        :label="$t('co-executors')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                    />
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" v-if="edit_task_type === 2">
                                <ValidationProvider ref="alignersAdd" rules="min:1" v-slot="{ errors, valid }">
                                    <UserSelection
                                        multiple
                                        v-model="alignersAdd"
                                        id="aligner"
                                        :label="$t('aligner')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                    />
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="auditorsAdd" rules="min:1" v-slot="{ errors, valid }">
                                    <UserSelection
                                        multiple
                                        v-model="auditorsAdd"
                                        id="auditors"
                                        :label="$t('auditors')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                    />
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="uved" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <div class="input_label">{{ $t('uved.self') }}</div>
                                    <v-autocomplete v-model="uved" :items="uvedItems"
                                                    :error="!valid"
                                                    :search-input.sync="uvedSearching"
                                                    item-text="name" item-value="id"
                                                    return-object
                                                    :loading="loadingUveds"
                                                    :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                    :label="$t('uved.self')"
                                                    @click:clear="uvedSearching=null;uved=null;uvedItems=[];companyItems = [];company = null;"
                                                    @click="clearUveds"
                                                    @click:append="redirectToUved(uved)"
                                                    @change="set_uved = uved"
                                                    autocomplete="off"
                                                    hide-details
                                                    :error-messages="errors"
                                                    :disabled="loadingUveds"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable>
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.bin_iin"></span>
                                        </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.bin_iin"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="pt-5">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="editParticipants()">
                            {{ $t('edit') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                v-model="dialogEditAuthor"
                v-if="dialogEditAuthor"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('change_author') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogEditAuthor = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>


                            <v-col cols="12">
                                <ValidationProvider ref="admin_id" rules="required" v-slot="{ errors, valid }">

                                    <UserSelection
                                        v-model="admin_id"
                                        id="responsible3"
                                        :label="$t('manager')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                    />
                                </ValidationProvider>

                            </v-col>

                        </v-row>

                    </v-card-text>
                    <v-card-actions class="pt-5">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="editAuthor()">
                            {{ $t('change') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialog_image_view"
                scrollable
                fullscreen
                v-if="!!image_view.comment"
            >
                <v-card>
                    <v-card-title class="px-2 mx-0">
                        <span class="pl-5 text-truncate">{{ image_view.comment.file_name }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="downloadFileMessage(image_view.comment.value, image_view.comment.file_name)"
                                       icon
                                >
                                    <v-icon>mdi-arrow-collapse-up mdi-flip-v</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('download') }}</span>
                        </v-tooltip>
                        <v-tooltip>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="ImageViewClose"
                                       icon>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('close') }}</span>
                        </v-tooltip>
                    </v-card-title>

                    <v-card-text style="height: 300px;">

                        <v-container fluid>
                            <v-row dense>
                                <v-col>
                                    <v-card>
                                        <v-img
                                            :alt="`${image_view.comment.file_name}`"
                                            :src="image_view.comment.value"


                                        >

                                        </v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                v-model="dialogAddParticipant"
                v-if="dialogAddParticipant"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('edit_participants') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogAddParticipant = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                    <UserSelection
                                        v-model="responsible[0]"
                                        id="responsible2"
                                        :label="$t('responsible')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable
                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-5">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="editParticipant()">
                            {{ $t('edit') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogUnlock"
                class="dialog_body"
            >
                <v-card>
                    <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0"
                        >{{ $t('reason_why_we_didnt_meet_deadline') }}
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <ValidationProvider ref="change_deadline" rules="required|min:1" v-slot="{ errors, valid }">

                                    <div class="input_label">{{ $t('change_deadline') }}</div>
                                    <datetime-picker v-model="change_deadline" validator-name="change_deadline"
                                                     :error="!valid"
                                                     validator-rules="required|min:1"
                                                     format="24hr"
                                                     :label="$t('change_deadline')" :locale="lang"
                                                     :min-date-time="$moment().format('YYYY-MM-DD')"
                                                     :timezone="$auth.user().timezone" timeFormat="HH:mm"
                                                     readonly
                                                     hide-details
                                                     :error-messages="errors"
                                                     :disabled="loading"
                                                     outlined
                                                     solo
                                                     flat
                                                     dense
                                                     color="primary"
                                                     class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                     clearable></datetime-picker>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="unlock_text" rules="required|min:10|max:62000"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea
                                        :label="$t('unlock_to_text')"
                                        :error="!valid"
                                        v-model="unlock_text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        rows="8"
                                        color="primary"
                                        auto-grow
                                        clearable outlined

                                        full-width
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                    >
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>

                        </v-card-text>
                        <v-card-actions class="justify-end pt-0">
                            <v-btn
                                text
                                @click="dialogUnlock = false"
                            >{{ $t('close') }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                :disabled="invalid || loading" :loading="loading"
                                text

                                @click="unlockRequestTask()"
                                color="primary" class="infinity_button"
                            >{{ $t('unlock') }}
                            </v-btn>

                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="displayRequestChangeDeadlineText"
                class="dialog_body"
            >
                <ValidationObserver ref="observerRequestChangeDeadlineText" v-slot="{ invalid, validated, passes, validate }">

                <v-card>




                        <v-card-text class="pa-1 pb-0">
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('reason_why_we_didnt_meet_deadline') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="closeDisplayRequestChangeDeadlineText()" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-col cols="12">
                                <ValidationProvider ref="requestChangeDeadlineText" rules="required|min:1|max:62000"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea
                                        :label="$t('reason_why_we_didnt_meet_deadline')"
                                        :error="!valid"
                                        v-model="requestChangeDeadlineText"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        rows="8"
                                        color="primary"
                                        auto-grow
                                        clearable outlined

                                        full-width
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                    >
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>

                        </v-card-text>
                        <v-card-actions class="pl-5 pt-0">
                            <v-btn
                                :disabled="invalid || loading" :loading="loading"
                                @click="updateDeadlineRequest()"
                                color="primary" class="infinity_button"
                            >{{ $t('sent') }}
                            </v-btn>
                            <v-btn
                                text
                                @click="closeDisplayRequestChangeDeadlineText()"
                            >{{ $t('close') }}
                            </v-btn>



                        </v-card-actions>

                </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogLinkTask"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('link_task') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogLinkTask = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <v-col class="py-0 pt-5" cols="12" sm="12">
                            <ValidationProvider ref="task" rules="min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{ $t('tasks') }}</div>
                                <v-combobox v-model="task" :items="taskAddItems"
                                            :error="!valid"
                                            :search-input.sync="taskSearching" hide-selected
                                            item-text="title" item-value="id" :loading="loadingTasks"
                                            :label="$t('tasks')" @click:clear="taskItems=[]"
                                            @click="clearTasks" @input="clearEmptyTaskItems(task)"
                                            multiple return-object autocomplete="off"
                                            hide-details
                                            :error-messages="errors"
                                            :disabled="loading"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                            clearable>
                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search_list">
                                                        <span class="ml-2 hidden-is-empty" v-text="item.title"></span>
                                                    </span>
                                    </template>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip v-if="item === Object(item)"
                                                class="input_chip"
                                                v-bind="attrs" :input-value="selected"
                                                @click:close="parent.selectItem(item)"
                                                :color="!item.deleted ? 'green  lighten-5' : 'red  lighten-5'"
                                                :title="!item.deleted ? $t('active') : $t('no_active')"
                                                link close>
                                            <span v-html="item.title"></span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ taskSearching ? $t('nothing_found_by', {'search': taskSearching}) : $t('nothing_found_name') }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="addTaskToSubTask()">
                            {{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="displayCancelStatusExecutor"
                class="dialog_body"
            >
                <v-card>
                    <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('reason') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeDialogStatusExecutor()" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-col cols="12">
                                <ValidationProvider ref="unlock_text" rules="required|min:10|max:62000"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea
                                        :label="$t('write_reason')"
                                        :error="!valid"
                                        v-model="status_executor_text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        rows="8"
                                        color="primary"
                                        auto-grow
                                        clearable outlined

                                        full-width
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                    >
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-card-text>
                        <v-card-actions class="justify-end pt-0">
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="invalid || loading" :loading="loading"
                                    text

                                    @click="cancelStatusExecutor()"
                                    color="primary" class="infinity_button"
                                >{{  $t(status_executor) }}
                                </v-btn>

                            </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <DocumentFormDialog
                :dialogForm="dialogForm"
                :document_flow_urls_form="document_flow_urls_form"
                :closeForm = "true"
                @dialog-form-close = "dialogFormClose"
            ></DocumentFormDialog>


            <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>

            <v-overlay :value="dragover" z-index="10" color="#244ba8">
                <v-row>
                    <v-col style="height: 400px;width:500px;"
                           class="grey darken-1"
                           @drop.prevent="dropFile"
                    >
                        <v-col style="height: 380px;width:490px; border: dashed 4px #fff;">

                            <v-card
                                height="350"
                                class="pa-2 grey darken-1 align-self-center"
                                elevation="0"

                            >
                                <v-col sm="12" class="text-center mt-10">
                                    <v-icon
                                        left style="font-size: 150px"

                                        class="text-center">
                                        mdi-file-upload-outline
                                    </v-icon>
                                </v-col>
                                <v-col sm="12" class="text-center font-weight-bold h5">{{ $t('drop_files_here_to_send_them') }}</v-col>
                            </v-card>
                        </v-col>
                    </v-col>
                </v-row>
            </v-overlay>
        </template>

        <template v-slot:side>
            <v-col cols="12" class="pa-0">
                <FormRight v-for="form in forms" :key="form.sort" entity_type="task" :entity_uuid="task_uuid" :form="form" />
            </v-col>

            <div v-if="edit_task_type === 3">
                <v-card class="side-block">
                    <v-card-title>
                        <div class="participants mr-auto">{{ $t('calculation_fields') }}</div>
                    </v-card-title>
                    <v-card-text>
                        <div class="text-info">
                            <div class="text-info__label" :class="error_loading_type">{{ $t('loading_type') }}</div>
                            <div class="text-info__value" :class="show_loading_type ? 'pt_3 pb-0': ''">
                                <div @click="showInput('loading_type')" v-if="!show_loading_type">
                                    <span v-if="loading_type && loading_type.name">{{ loading_type.name }}</span>
                                    <span v-else class="client_no_text" :class="error_loading_type">{{ $t('select_value') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="loading_type" rules="required" v-slot="{ errors, valid }">
                                        <v-select
                                            v-model="loading_type"
                                            :disabled="loading"
                                            :items="loadingTypeItems"
                                            autofocus
                                            @change="changeField('loading_type')"
                                            @focusout="changeField('loading_type')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            item-text="name" item-value="id"
                                            return-object
                                            :label="$t('loading_type')"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info_-label" :class="error_from_city">{{ $t('from_city') }}</div>
                            <div class="text-info__value" :class="show_from_city ? 'pt_3 pb-0': ''">
                                <div @click="showInput('from_city')" v-if="!show_from_city">
                                    <span v-if="from_city && from_city !== 'null'">{{ from_city }}</span>
                                    <span v-else class="client_no_text" :class="error_from_city">{{ $t('enter_text') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="bin_inn" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-text-field
                                            autofocus
                                            @focusout="saveField('from_city')"
                                            v-model="from_city" type="text" :error-messages="errors"
                                            :disabled="loading"
                                            color="primary"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info__label" :class="error_to_city">{{ $t('to_city') }}</div>
                            <div class="text-info__value" :class="show_to_city ? 'pt_3 pb-0': ''">
                                <div @click="showInput('to_city')" v-if="!show_to_city">
                                    <span v-if="to_city && to_city !== 'null'">{{ to_city }}</span>
                                    <span v-else class="client_no_text" :class="error_to_city">{{ $t('enter_text') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="bin_inn" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-text-field
                                            autofocus
                                            @focusout="saveField('to_city')"
                                            v-model="to_city" type="text"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            color="primary"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info__label">{{ $t('goods') }}</div>
                            <div class="text-info__value" :class="show_goods ? 'pt_3 pb-0': ''">
                                <div @click="showInput('goods')" v-if="!show_goods">
                                    <span v-if="goods && goods !== 'null'" class="description_text cursor_pointer text-info__value">{{ goods }}</span>
                                    <span v-else class="client_no_text">{{ $t('goods') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="goods" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-textarea
                                            v-model="goods"
                                            type="text"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            :label="$t('enter_text')"
                                            rows="5"
                                            color="primary"
                                            auto-grow
                                            clearable outlined
                                            hide-details
                                            full-width
                                            autofocus
                                            @focusout="saveField('goods')"
                                            background-color="white lighten-2"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                        </v-textarea>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info__label">{{ $t('insurance') }}</div>
                            <div class="text-info__value" :class="show_insurance ? 'pt_3 pb-0': ''">
                                <div @click="showInput('insurance')" v-if="!show_insurance">
                                    <span v-if="insurance === 1">{{ $t('yes') }}</span>
                                    <span v-else-if="insurance === 0">{{ $t('no') }}</span>
                                    <span v-else-if="insurance === -1">{{ $t('no') }}</span>
                                    <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="insurance" rules="required" v-slot="{ errors, valid }">
                                        <v-select v-model="insurance" :disabled="loading" :items="itemYN"
                                                  autofocus
                                                  @change="changeField('insurance')"
                                                  @focusout="changeField('insurance')"
                                                  :error="!valid" :error-messages="errors"
                                                  item-text="name" item-value="id"
                                                  :label="$t('insurance')"
                                                  hide-details
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info__label">{{ $t('dangerous_cargo') }}</div>
                            <div class="text-info__value" :class="show_dangerous_cargo ? 'pt_3 pb-0': ''">
                                <div @click="showInput('dangerous_cargo')" v-if="!show_dangerous_cargo">
                                    <span v-if="dangerous_cargo === 1">{{ $t('yes') }}</span>
                                    <span v-else-if="dangerous_cargo === 0">{{ $t('no') }}</span>
                                    <span v-else-if="dangerous_cargo === -1">{{ $t('no') }}</span>
                                    <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="dangerous_cargo" rules="required" v-slot="{ errors, valid }">
                                        <v-select v-model="dangerous_cargo" :disabled="loading" :items="itemYN"
                                                  autofocus
                                                  @change="changeField('dangerous_cargo')"
                                                  @focusout="changeField('dangerous_cargo')"
                                                  :error="!valid" :error-messages="errors"
                                                  item-text="name" item-value="id"
                                                  :label="$t('dangerous_cargo')"
                                                  hide-details
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info__label" :class="error_weight">{{ $t('weight_kg') }}</div>
                            <div class="text-info__value" :class="show_weight ? 'pt_3 pb-0': ''">
                                <div @click="showInput('weight')" v-if="!show_weight">
                                    <span v-if="weight && weight !== 'null'">{{ weight }}</span>
                                    <span v-else class="client_no_text" :class="error_weight">{{ $t('enter_text') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="weight" rules="float_text|max:255" v-slot="{ errors, valid }">
                                        <v-text-field
                                            autofocus
                                            @focusout=" valid ? saveField('weight') : ''"
                                            v-model="weight" type="text" :error-messages="errors"
                                            :disabled="loading"
                                            color="primary"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="text-info">
                            <div class="text-info__label" :class="error_volume">{{ $t('volume') }}</div>
                            <div class="text-info__value" :class="show_volume ? 'pt_3 pb-0': ''">
                                <div @click="showInput('volume')" v-if="!show_volume">
                                    <span v-if="volume && volume !== 'null'">{{ volume }}</span>
                                    <span v-else class="client_no_text" :class="error_volume">{{ $t('enter_text') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="volume" rules="float_text|max:255" v-slot="{ errors, valid }">
                                        <v-text-field
                                            autofocus
                                            @focusout="valid ? saveField('volume') : ''"
                                            v-model="volume" type="text" :error-messages="errors"
                                            :disabled="loading"
                                            color="primary"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="text-info">
                            <div class="text-info__label">{{ $t('stackable_cargo') }}</div>
                            <div class="text-info__value" :class="show_stackable_cargo ? 'pt_3 pb-0': ''">
                                <div @click="showInput('stackable_cargo')" v-if="!show_stackable_cargo">
                                    <span v-if="stackable_cargo === 1">{{ $t('yes') }}</span>
                                    <span v-else-if="stackable_cargo === 0">{{ $t('no') }}</span>
                                    <span v-else-if="stackable_cargo === -1">{{ $t('no') }}</span>
                                    <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="stackable_cargo" rules="required" v-slot="{ errors, valid }">
                                        <v-select v-model="stackable_cargo" :disabled="loading" :items="itemYN"
                                                  autofocus
                                                  @change="changeField('stackable_cargo')"
                                                  @focusout="changeField('stackable_cargo')"
                                                  :error="!valid" :error-messages="errors"
                                                  item-text="name" item-value="id"
                                                  :label="$t('stackable_cargo')"
                                                  hide-details
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="text-info">
                            <div class="text-info__label" :class="error_terms_delivery">{{ $t('terms_delivery') }}</div>
                            <div class="text-info__value" :class="show_terms_delivery ? 'pt_3 pb-0': ''">
                                <div @click="showInput('terms_delivery')" v-if="!show_terms_delivery">
                                    <span v-if="terms_delivery && terms_delivery !== 'null'">{{ terms_delivery }}</span>
                                    <span v-else class="client_no_text" :class="error_terms_delivery">{{ $t('enter_text') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="terms_delivery" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <v-text-field
                                            autofocus
                                            @focusout="saveField('terms_delivery')"
                                            @input="checkTermsDelivery"
                                            v-model="terms_delivery" type="text" :error-messages="errors"
                                            :disabled="loading"
                                            color="primary"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div v-if="show_tr_terms_delivery" class="text-info">
                            <div class="text-info__value" :class="show_place_argo_pickup ? 'pt_3 pb-0': ''">
                                <div @click="showInput('place_argo_pickup')" v-if="!show_place_argo_pickup">
                                    <span v-if="place_argo_pickup && place_argo_pickup !== 'null'">{{ place_argo_pickup }}</span>
                                    <span v-else class="client_no_text">{{ $t('place_argo_pickup') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="place_argo_pickup" rules="min:1|max:255" v-slot="{ errors, valid }">
                                        <div class="input_label pl-0">{{ $t('place_argo_pickup') }}</div>
                                        <v-textarea
                                            v-model="place_argo_pickup"
                                            type="text"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            :label="$t('enter_text')"
                                            rows="5"
                                            color="primary"
                                            auto-grow
                                            clearable outlined
                                            hide-details
                                            full-width
                                            autofocus
                                            @focusout="saveField('place_argo_pickup')"
                                            background-color="white lighten-2"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                        </v-textarea>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="text-info">
                            <div class="text-info__label" :class="error_field_type_transport">{{ $t('type_transport') }}</div>
                            <div class="text-info__value" :class="show_field_type_transport ? 'pt_3 pb-0': ''">
                                <div @click="showInput('field_type_transport')" v-if="!show_field_type_transport">
                                    <span v-if="field_type_transport && field_type_transport.length > 0">{{ field_type_transport_text }}</span>
                                    <span v-else class="client_no_text" :class="error_field_type_transport">{{ $t('select_value') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="field_type_transport" rules="required" v-slot="{ errors, valid }">
                                        <v-select
                                            v-model="field_type_transport"
                                            :disabled="loading"
                                            :items="field_type_transportItems"
                                            autofocus
                                            @focusout="changeField('field_type_transport')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            item-text="name"
                                            item-value="id"
                                            :label="$t('field_type_transport')"
                                            hide-details
                                            multiple
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="text-info">
                            <div class="text-info__label" :class="error_field_urgency_departure">{{ $t('urgency_departure') }}</div>
                            <div class="text-info__value" :class="show_field_urgency_departure ? 'pt_3 pb-0': ''">
                                <div @click="showInput('field_urgency_departure')" v-if="!show_field_urgency_departure">
                                    <span v-if="field_urgency_departure && field_urgency_departure.length > 0">{{ field_urgency_departure_text }}</span>
                                    <span v-else class="client_no_text" :class="error_field_urgency_departure">{{ $t('select_value') }}</span>
                                </div>
                                <div v-else>
                                    <ValidationProvider ref="field_urgency_departure" rules="required" v-slot="{ errors, valid }">
                                        <v-select
                                            v-model="field_urgency_departure"
                                            :disabled="loading"
                                            :items="field_urgency_departureItems"
                                            autofocus
                                            @focusout="changeField('field_urgency_departure')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            item-text="name"
                                            item-value="id"
                                            :label="$t('urgency_departure')"
                                            hide-details
                                            multiple
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="text-info" v-if="date_cargo_readiness">
                            <div class="text-info__label">{{ $t('date_cargo_readiness') }}</div>
                            <div class="text-info__value">
                                <template v-if="date_cargo_readiness">{{ $moment(date_cargo_readiness).format('DD.MM.YYYY') }}</template>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>

            <v-col cols="12" class="px-0 pb-0">
                <v-card class="side-block">
                    <v-card-title>
                        <div class="participants">{{ $t('additional_information') }}</div>
                    </v-card-title>
                    <v-card-text>
                        <div class="text-info">
                            <div class="text-info__label">{{ $t('date_created') }}</div>
                            <div class="text-info__value">
                                <template v-if="created_at">{{ $moment(created_at).format('DD.MM.YYYY HH:mm') }}</template>
                            </div>
                        </div>
                        <div class="text-info" v-if="start_at">
                            <div class="text-info__label">{{ $t('start') }}</div>
                            <div class="text-info__value">
                                {{ $moment(start_at).format('DD.MM.YYYY HH:mm') }}
                            </div>
                        </div>
                        <div class="text-info" v-if="day_work > 0">
                            <div class="text-info__label">{{ $t('duration') }}</div>
                            <div class="text-info__value">
                                {{ $t('for_day', {'day': day_work}) }}
                            </div>
                        </div>
                        <div class="text-info" v-if="completion_date">
                            <div class="text-info__label">{{ $t('finish') }}</div>
                            <div class="text-info__value">
                                {{ $moment(completion_date).format('DD.MM.YYYY HH:mm') }}
                            </div>
                        </div>
                        <div class="text-info" v-if="deadline">
                            <div class="text-info__label">{{ $t('deadline') }}</div>
                            <div class="text-info__value">
                                <div :class="is_author ? 'cursor-pointer' : ''" @click="is_author ? openDialogDate() : (is_responsible ? requestChangeDeadline() : '')">
                                    {{ deadline }}
                                </div>
                                <div v-show="false">
                                    <ValidationProvider ref="deadline" rules="required|min:1" v-slot="{ errors, valid }">
                                        <datetime-picker-text
                                            v-model="deadline"
                                            validator-name="deadline"
                                            :error="!valid"
                                            validator-rules="required|min:1"
                                            format="24hr"
                                            :label="$t('deadline')"
                                            :locale="lang"
                                            :min-date-time="$moment().format('YYYY-MM-DD')"
                                            :timezone="$auth.user().timezone"
                                            readonly
                                            hide-details
                                            :displayDateTimePicker="displayDateTimePicker"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0  input-text"
                                            :clearable="false"
                                            timeFormat="HH:mm"
                                            @change="updateDeadline('deadline',deadline);"
                                        ></datetime-picker-text>
                                    </ValidationProvider>

                                    <ValidationProvider ref="deadline_request" rules="required|min:1" v-slot="{ errors, valid }">
                                        <datetime-picker-text
                                            v-model="deadline_request"
                                            validator-name="deadline_request"
                                            :error="!valid"
                                            validator-rules="required|min:1"
                                            format="24hr"
                                            :label="$t('deadline')"
                                            :locale="lang"
                                            :min-date-time="$moment().format('YYYY-MM-DD')"
                                            :timezone="$auth.user().timezone"
                                            readonly
                                            hide-details
                                            :displayDateTimePicker="displayRequestChangeDeadline"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0  input-text"
                                            :clearable="false"
                                            timeFormat="HH:mm"
                                            @change="openDisplayRequestChangeDeadlineText();"
                                        ></datetime-picker-text>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="text-info" v-if="task_score_admin && task_score_admin.score && is_responsible">
                            <div class="text-info__value" :style="'color: '+task_score_admin.color+' !important;'">
                                {{ task_score_admin.score > 0 ? $t('bonus')  : $t('fine') }}  {{task_score_admin.score}}
                            </div>
                        </div>

                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" class="pa-0">
                <v-card class="side-block" style="margin-top: 15px">
                    <div>
                        <div class="d-flex">
                            <div class="pa-4 participants mr-auto">{{ $t('participants') }}</div>
                            <div class="pa-4">
                                <v-icon
                                    v-if="!closed"
                                    :disabled="(edit_task_type === 2 && author.id !== $auth.user().id) || userBlock"
                                    size="18"
                                    color="secondary"
                                    class="add_icon"
                                    @click="dialogAddParticipants=true"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </div>
                    </div>
                    <v-card-text>
                        <v-row>
                            <v-col class="sub_participants" v-if="!$vuetify.breakpoint.xlOnly">{{ $t('author') }}</v-col>
                            <v-col cols="12" sm="12" class="py-1" v-if="author && author.name">
                                <v-list class="my-0 py-0">
                                    <template>
                                        <div v-if="$vuetify.breakpoint.xlOnly" class="my-0 sub_participants">{{ $t('author') }}</div>
                                        <v-list-item class="px-0">
                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                <img v-if="author.photo !== '/img/avatar.png'" :src="author.photo" :alt="author.name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{ 'settingsIcon' }}
                                                </v-icon>
                                            </v-list-item-avatar>

                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title v-text="author.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="formatPhoneNumber(author.phone)"></v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-col>

                            <template v-if="responsible && responsible[0] !== null && responsible[0] !== undefined && responsible[0].name !== null">
                                <v-col class="sub_participants">{{ $t('responsible') }}</v-col>
                                <v-col cols="12" class="py-1"
                                       v-for="responsible in responsible"
                                       :key="'responsible_1_'+responsible.id"
                                >
                                    <v-list class="my-0 py-0">
                                        <template>
                                             <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="responsible.photo !== '/img/avatar.png'" :src="responsible.photo" :alt="responsible.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="responsible.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="formatPhoneNumber(responsible.phone)"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>
                            </template>

                            <v-col cols="12" class="sub_participants" v-if="executors && executors.length > 0">{{ $t('co-executors') }}</v-col>
                            <v-col cols="12" class="py-1" v-for="item in executors" :key="'executor_1_'+item.id">
                                <v-list class="my-0 py-0">
                                    <template>
                                        <v-list-item class="px-0">
                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{ 'settingsIcon' }}
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="getStatusName(item.task_status_id)"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="item.text" v-text="item.text" :title="item.text"></v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-col>

                            <v-col cols="12" class="sub_participants" v-if="auditors.length > 0">{{ $t('auditors') }}</v-col>
                            <v-col cols="12" class="py-1" v-for="item in auditors" :key="'auditor_1_'+item.id">
                                <v-list class="my-0 py-0">
                                    <template>
                                        <v-list-item class="px-0">
                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{ 'settingsIcon' }}
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-col>

                            <v-col cols="12" class="sub_participants" v-if="alignerAllItems.length > 0">{{ $t('aligner') }}</v-col>
                            <v-col cols="12" sm="12" class="py-1" v-for="aligner in alignerAllItems"
                                   :key="'aligner_1_'+aligner.id">
                                <v-list class="my-0 py-0">
                                    <template>
                                        <v-list-item class="px-0">
                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                <img v-if="aligner.photo !== '/img/avatar.png'" :src="aligner.photo" :alt="aligner.name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{ 'settingsIcon' }}
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title v-text="aligner.name"></v-list-item-title>
                                                <v-list-item-subtitle v-if="(aligner.reason && !aligner.agreed)" v-text="$t('rejected_text',{reason:aligner.reason})"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-else-if="(aligner.agreed)" v-text="$t('agreed_all')"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-else v-text="$t('decision_not_adopted')"></v-list-item-subtitle>

                                            </v-list-item-content>

                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-col>

                            <v-col cols="12" sm="12" class="py-1" v-if="uved_show && uved_show.id ">
                                <v-list class="my-0 py-0">
                                    <template>
                                        <div class="my-0 sub_participants">{{ $t('uved.self') }}</div>
                                        <v-list-item class="px-0">
                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                <img v-if="uved_show.photo !== '/img/avatar.png'" :src="uved_show.photo" :alt="uved_show.name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{ 'settingsIcon' }}
                                                </v-icon>
                                            </v-list-item-avatar>

                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title class="cursor-pointer">
                                                    <v-btn text plain :to="'/uved/' + uved_show.uuid + '/show'" class="button_link">
                                                        {{ uved_show.name }}
                                                    </v-btn>
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="uved_show.phone !== null" v-text="formatPhoneNumber(uved_show.phone.phone)"></v-list-item-subtitle>
                                                <v-list-item-subtitle v-else v-text="uved_show.bin_inn"></v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" class="pa-0">
                <v-card class="side-block" style="margin-top: 15px">
                    <div>
                        <div class="d-flex">
                            <div class="pa-4 participants mr-auto">{{ $t('files') }}{{ accompanying_document_urls.length > 0 ? ': ' + accompanying_document_urls.length : '' }}</div>
                            <div class="pa-4">
                                <v-icon
                                    v-if="!closed"
                                    :disabled="userBlock || (edit_task_type === 2 && task_status !== 1)"
                                    size="18"
                                    color="secondary"
                                    class="add_icon"
                                    @click="document.getElementById('accompanying_documents').click()"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </div>
                    </div>
                    <div>
                        <v-card-text class="mt-0 pt-0" v-if="accompanying_document_urls.length > 0">
                            <v-list>
                                <v-list-item outlined class="file_item px-0" v-for="file in accompanying_document_urls" :key="file.id + '_' + Date.now()">
                                    <v-list-item-icon>
                                        <v-img
                                            max-width="45"
                                            :alt="`${file.name}`"
                                            :src="file.url"
                                            max-height="30"
                                        ></v-img>
                                        <v-icon
                                            x-large
                                            size="10"
                                            color="primary"
                                            v-if="!mimeTypeImage(file.type)">mdi-file-document-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="file_text">
                                        <ContextMenuFile :file="file" @add-document-form ="addDocumentForm"  @open-file="openFileContext"></ContextMenuFile>
                                        <v-list-item-subtitle v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="file.author.name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                                        <v-icon color="primary" class="mr-2" :title="$t('download')" @click="downloadFile(file)">mdi-cloud-upload</v-icon>
                                        <v-icon color="primary" :disabled="closed" v-if="file.uuid && (is_author || can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteFileServer(file)">mdi-delete-outline</v-icon>
                                        <v-icon color="primary" :disabled="closed" v-if="!file.uuid && (is_author || can(['administrator', 'general_manager']))" :title="$t('delete')" @click="deleteAccompanyingDocumentFile(file)">mdi-delete-outline</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>

            <v-col class="py-0" cols="12">
                <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                <v-file-input
                    hide-input prepend-icon=""

                    :label="$t('accompanying_documents')"
                    v-model="accompanying_documents"
                    id="accompanying_documents"
                    multiple
                    @change="accompanyingDocumentsPreviewFiles"
                >
                </v-file-input>
            </v-col>

            <v-col class="py-0" cols="12">
                <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                <v-file-input hide-input prepend-icon=""
                              v-model="files"
                              id="file"
                              multiple
                              @change="previewFiles"
                >
                </v-file-input>
            </v-col>

            <v-col class="py-0" cols="12" v-show="false">
                <ValidationProvider ref="extend_deadline" rules="required|min:1" v-slot="{ errors, valid }">
                    <datetime-picker-text
                        v-model="extend_deadline"
                        validator-name="extend_deadline"
                        :error="!valid"
                        validator-rules="required|min:1"
                        format="24hr"
                        :label="$t('deadline')"
                        :locale="lang"
                        :min-date-time="computedMinDateTime"
                        :timezone="$auth.user().timezone"
                        readonly
                        hide-details
                        :displayDateTimePicker="displayDateTimePickerExtendDeadline"
                        :error-messages="errors"
                        :disabled="loading"
                        flat
                        dense
                        color="primary"
                        class="ma-0 pa-0 my-0 py-0  input-text"
                        :clearable="false"
                        timeFormat="HH:mm"
                        @change="updateStatusDeadline('extend_deadline',extend_deadline);"
                    ></datetime-picker-text>
                </ValidationProvider>
            </v-col>
        </template>
    </PageLayout>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import CalendarCase from "../components/CalendarCase.vue";
import DatetimePickerText from "../components/DatetimePickerText";
import DatetimePicker from "../components/DatetimePicker";
import PageLayout from "@/components/Leentech/PageLayout.vue"
import FormRight from '@/components/Eav/FormRight.vue';
import FormTabField from '@/components/Eav/FormTabField.vue';
import UserSelection from "@/components/Form/UserSelection.vue";
import Btn from "@/components/Form/Btn.vue";
import ResponsiveTabs from "@/components/Leentech/ResponsiveTabs.vue";
import ResponsiveTab from "@/components/Leentech/ResponsiveTab.vue"
import OpenFile from '@/components/OpenFile.vue';
import User from "@/components/User.vue";
import Messages from "@/components/Messages.vue";
import ShareView from "@/plugins/mixins/ShareView";
import BackView from "@/plugins/mixins/BackView";
import ContextMenuFile from "@/components/ContextMenuFile.vue";
import DocumentFormDialog from '@/components/DocumentFormDialog.vue';

export default {
    name: 'TaskShow',
    components: {
        Messages,
        User,
        UserSelection,
        DatetimePickerText,
        DatetimePicker,
        ValidationProvider,
        ValidationObserver,
        CalendarCase,
        PageLayout,
        FormRight,
        FormTabField,
        Btn,
        ResponsiveTabs,
        ResponsiveTab,
        OpenFile,
        ContextMenuFile,
        DocumentFormDialog
    },

    inject: ['forceRerender'],

    props: {
        id: {
            type: String,
            default: null
        },
    },

    mixins: [
        ShareView,
        BackView
    ],

    data() {
        return {
            closed: false,
            dialogForm: false,
            document_flow_urls_form: [],
            forms: [],
            tabs: [],
            heading: null,
            author: {},
            uved_show: {},
            set_uved: {},
            progress: 0,
            loading: false,
            tab: 0,
            number: null,
            task_statusItems: [],
            task_status: null,
            status_color: '#B3B3B3',
            status_name: null,
            company: null,
            companyItems: [],
            companySearching: null,
            loadingCompanies: false,
            task_type: null,
            taskTypeItems: [],
            taskTypeSearching: null,
            loadingTaskTypes: false,
            text_task: "",
            text_task_edit: false,
            text: '',
            responsible: null,
            responsibleAdd: null,
            responsibleItems: [],
            responsibleSearching: null,
            loadingResponsibles: false,
            auditorsAdd: [],
            auditors: [],
            executorsAdd: [],
            executors: [],
            auditor: null,
            auditorItems: [],
            aligner: null,
            alignerItems: [],
            alignersAdd: [],
            loadingAligners: false,
            alignerSearching: null,
            alignerAllItems: [],
            auditorSearching: null,
            loadingAuditors: false,
            dragover: false,
            document,
            documents: [],
            files: [],
            document_urls: [],
            dialog_file: false,
            file_urls: [],
            task_id: null,
            task_uuid: null,
            request: [],
            answer: [],
            blur_stop: 0,
            task_order_total: 0,
            task_order_nds_total: 0,
            task_order_no_nds_total: 0,
            chatMessages: [],
            chatMessagesDate: [],
            toolbar_reply: false,
            task_active: true,
            reply_msg: {},
            reply_name: null,
            reply_uuid: null,
            message_menu: [
                {title: this.$t('delete'), click: "delete"},
                {title: this.$t('reply'), click: "reply"},
            ],
            created_at: null,
            date_cargo_readiness: null,
            accompanying_documents: [],
            accompanying_document_urls: [],
            task_documents: [],
            accompanying_documents_preview: [],
            accompanying_documents_dialog: false,
            not_accepted: 0,
            accepted: 0,
            rejected: 0,
            is_aligner: null,
            is_responsible: null,
            is_executor: null,
            is_author: null,
            is_agreed: false,
            is_urgent: false,
            is_auditor: false,
            dialogStatusApproval: false,
            dialogStatusApprovalFile: false,
            task_document_id: null,
            reject_text: null,
            subtask_headers: [
                {
                    text: this.$t('task_name'),
                    align: "left",
                    sortable: true,
                    value: "title"
                },
                {
                    text: this.$t('task_status'),
                    sortable: false,
                    value: "task_status"
                },
                {
                    text: this.$t('date'),
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: false,
                    value: "admin.name"
                }
            ],
            subtaskItems: [],
            totalSubTasks: 0,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            sortBy: "id",
            sortDir: false,
            display_task: false,
            display_deal: false,
            display_case: false,
            deal_headers: [
                {
                    text: this.$t('number_deal'),
                    align: "left",
                    sortable: true,
                    value: "number"
                },
                {
                    text: this.$t('deal_status'),
                    sortable: false,
                    value: "deal_status"
                },
                {
                    text: this.$t('date'),
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: false,
                    value: "admin"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active"
                },
            ],
            dealItems: [],
            itemYN: [
                {
                    id: -1,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            loadingTaskStatus: false,
            loadingMessages: false,
            loadingCalendarCase: false,
            loadingSubtask: false,
            loadingDeal: false,
            loadingTask: false,
            dialogLinkDeal: false,
            deal: null,
            dealAddItems: [],
            dealSearching: null,
            loadingDeals: false,
            dialogAddParticipant: false,
            dialogAddParticipants: false,
            uved: null,
            uvedItems: [],
            uvedSearching: null,
            loadingUveds: false,
            favorite: false,
            completion_date: null,
            start_at: null,
            deadline: null,
            extend_deadline: null,
            status_deadline: null,
            day_work: 0,
            dialog_image_view: false,
            image_view: {},
            show_title: false,
            title: null,
            deadline_request:null,
            displayRequestChangeDeadline: false,
            displayRequestChangeDeadlineText: false,
            requestChangeDeadlineText: null,
            displayDateTimePicker: false,
            displayDateTimePickerExtendDeadline: false,
            loading_type: null,
            loadingTypeItems: [],
            type_transport: null,
            typeTransportItems: [],
            urgency_departure: null,
            urgencyDepartureItems: [],


            error_from_city: '',
            from_city: null,
            error_to_city: '',
            to_city: null,
            special_text: null,
            dangerous_cargo: null,
            stackable_cargo: null,
            show_loading_type: false,
            error_loading_type: '',
            show_from_city: false,
            show_to_city: false,
            show_special_text: false,
            show_dangerous_cargo: false,
            show_stackable_cargo: false,
            edit_task_type: null,



            name: null,
            showSuggestions: false,
            suggestions: [],
            menuX: 0,
            menuY: 0,
            inputAfterAt: "",
            activeIndex: 0,
            heightSuggestions: 150,
            dialogUnlock: false,
            is_overdue: true,
            extend_deadline_uuid: null,
            unlock_text: null,
            change_deadline: null,
            extension_date: null,
            extension_form: false,
            extension_button: false,
            error_weight: '',
            weight: null,
            show_weight: false,
            error_terms_delivery: '',
            terms_delivery: null,
            show_terms_delivery: false,
            show_tr_terms_delivery: false,
            error_place_argo_pickup: '',
            place_argo_pickup: null,
            show_place_argo_pickup: false,
            error_insurance: '',
            insurance: null,
            show_insurance: false,
            field_type_transport: [],
            field_type_transport_text: null,
            field_type_transportItems: [],
            show_field_type_transport: false,
            error_field_type_transport: '',
            field_urgency_departure: [],
            field_urgency_departure_text: null,
            field_urgency_departureItems: [],
            show_field_urgency_departure: false,
            error_field_urgency_departure: '',
            dialogEditAuthor: false,
            admin_id: null,
            adminItems: [],
            error_volume: '',
            volume: null,
            show_volume: false,
            error_goods: '',
            goods: null,
            show_goods: false,
            dialogLinkTask: false,
            taskAddItems: [],
            taskSearching: null,
            loadingTasks: false,
            task: null,
            all_agreed: false,
            dialogDelegate: false,
            delegatingToUsers: null,
            dialogOpenFile: false,
            fileOpen: {},
            executor_task_status_id: 0,
            executor_text: null,
            executor_date_approval: null,
            displayCancelStatusExecutor: false,
            status_executor: null,
            status_executor_text: null,
            request_change_deadline: {},
            task_score_admin: {},

        }
    },
    computed: {
        ...mapGetters(['userBlock', 'language', 'listLanguages', 'lang', 'timezone', 'itemsPerPage', 'perPageItems']),

        languages() {
            return this.listLanguages
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        },
        filteredSuggestions() {
            // Фильтрация предложений на основе текста после символа "@"
            let suggestionItems = this.suggestions.filter((suggestion) =>
                suggestion.toLowerCase().includes(this.inputAfterAt.toLowerCase())
            );
            if (suggestionItems.length >= 3) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 150;
                this.menuY = textareaRect.top - 5;
            } else if (suggestionItems.length === 2) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 100;
                this.menuY = textareaRect.top - 4.5;
            } else if (suggestionItems.length === 1) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 50;
                this.menuY = textareaRect.top - 4.9;
            } else if (suggestionItems.length === 0) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 0;
                this.menuY = textareaRect.top - 5.1;
            }
            return suggestionItems;
        },

        isUserResponsible() {
            let user = this.$auth.user();

            if(this.responsible) {
                let currentResp = this.responsible.find((item) => {
                    return user.id === item.id
                });

                if (currentResp) {
                    return true;
                }
            }

            return false
        },

        canDelegate() {
            return this.isUserResponsible && this.task_status === 1;
        },
        computedMinDateTime() {
            const today = this.$moment().format('YYYY-MM-DD');
            const deadline = this.$moment(this.deadline).format('YYYY-MM-DD');
            return this.$moment.max(this.$moment(today), this.$moment(deadline)).format('YYYY-MM-DD');
        }
    },

    async mounted() {
        await this.init();
        const observer = new MutationObserver(() => {
            if (this.$refs.calendarCase) {
                this.getCalendarCase();
                observer.disconnect(); // Останавливаем наблюдение после обнаружения элемента
            }
        });
        // Начинаем наблюдать за изменениями в DOM в корневом элементе компонента
        observer.observe(this.$el, { childList: true, subtree: true });
    },

    watch: {
        id: {
            handler: async function (val) {
                await this.init();
            },
            immediate: true
        },

        companySearching: debounce(function (val) {
            if (val && !this.company) {
                this.getCompanies(val)
            }
        }, 500),
        responsibleSearching: debounce(function (val) {
            if (val) {
                this.getResponsibles(val)
            }
        }, 500),
        alignerSearching: debounce(function (val) {
            if (val) {
                this.getAligners(val)
            }
        }, 500),
        auditorSearching: debounce(function (val) {
            if (val) {
                this.getAuditors(val)
            }
        }, 500),
        taskTypeSearching: debounce(function (val) {
            if (val) {
                this.getTaskTypes(val)
            }
        }, 500),
        dealSearching: debounce(function (val) {
            if (val) {
                this.getAddDeals(val)
            }
        }, 500),
        uvedSearching: debounce(function (val) {
            if (val && !this.uved) {
                this.getUveds(val)
            }
        }, 500),
        taskSearching: debounce(function (val) {
            if (val) {
                this.getTasks(val)
            }
        }, 500),
    },

    methods: {
        ...mapActions(['readEntityNotification', 'fetchNotificationGroups', 'fetchNotifications']),

        async init() {
            await this.checkCreate()
            await this.getRequestChangeDeadline()
            await this.getTaps()
            await this.getGroups()
            await this.getTaskStatuses()
            await this.getSubtasks()
            await this.getDeals()

            if (this.$refs.calendarCase) {
                this.getCalendarCase();
            }

            if(this.$route.params.id) {
                this.readEntityNotification({entity: 'task', uuid: this.$route.params.id})
                    .then(() => {
                        this.fetchNotifications()
                            .then(() => {
                                this.fetchNotificationGroups()
                            });
                    })
            }
        },

        showDelegateDialog() {
            this.dialogDelegate = true;
        },

        showTab(item){
            this.display_open('close_all')
            this.tabs.forEach(tab => {
                if (tab.name === item.name && tab.tab_id === item.tab_id) {
                    tab.show = true;
                } else {
                    tab.show = false;
                }
            });
        },

        async getTaps(){

            this.loading = true;
            let params = {};

            params.type_form = 'tabs';
            params.entity_type = 'task';
            params.entity_uuid = this.$route.params.id;
            params.sub_entity_type = 'task_type';
            params.sub_entity_id = this.edit_task_type;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.tabs = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_tabs'))
                })
                .finally(end => {
                    this.loading = false
                })

        },

        async getGroups(){

            this.loading = true;
            let params = {};

            params.type_form = 'groups';
            params.entity_type = 'task';
            params.entity_uuid = this.$route.params.id;
            params.sub_entity_type = 'task_type';
            params.sub_entity_id = this.edit_task_type;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.forms = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_groups'))
                })
                .finally(end => {
                    this.loading = false
                })

        },

        async checkTermsDelivery() {
            if (this.terms_delivery !== null) {
                if (this.terms_delivery.toUpperCase().includes('EXW')) {
                    this.show_tr_terms_delivery = true;
                } else {
                    this.show_tr_terms_delivery = false
                }
            }

        },


        async approvalSheet(){
            var _this = this;
            this.loading = true


            this.loading = true
            let params = {}
            if (this.language) {
                params.language =  this.language
            }
            if (this.$route.params.id) {
                params.task_uuid =  this.$route.params.id
            }
            await this.$http
                .get(`admin/approval_sheet/task`, {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.url){
                        this.openFile(res.body.data)
                        //this.downloadFile(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async getAdminLogins() {
            this.loading = true
            let params = {}
            params.active = this.active
            params.perPage = 1000
            params.filter = 'login'
            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.suggestions = res.body.data
                })
                .catch(err => {
                    this.suggestions = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async getAuthors() {
            if (this.adminItems.length <= 0) {
                this.loading = true
                let params = {}
                params.active = this.active
                params.perPage = 1000;
                params.sortBy = 'name';
                params.sortDir = 'asc';
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },
        async editAuthor() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }

            if (this.admin_id.id) {
                formData.append('admin_id', this.admin_id.id)
            }
            else if (this.admin_id) {
                formData.append('admin_id', this.admin_id)
            }


            // Add
            await this.$http
                .put('admin/task_author', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.author = res.body.data
                    this.dialogEditAuthor = false;

                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        editAuthorDialog() {
            if (this.is_author || this.can(['administrator', 'director', 'general_manager'])) {
                this.getAuthors();
                this.dialogEditAuthor = true
            }
        },
        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
        },
        rowClass2() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
        },
        display_open(type) {
            switch (type) {
                case 'display_task':
                    this.display_deal = false;
                    this.display_case = false;
                    this.display_task = (this.display_task) ? false : true;
                    break;
                case 'display_deal':
                    this.display_deal = (this.display_deal) ? false : true;
                    this.display_task = false;
                    this.display_case = false;
                    break;
                case 'display_case':
                    this.display_case = (this.display_case) ? false : true;
                    this.display_task = false;
                    this.display_deal = false;
                    break;
                case 'close_all':
                    this.display_case = false;
                    this.display_task = false;
                    this.display_deal = false;
                    break;
            }
            if(type !== 'close_all'){
                this.tabs.forEach(tab => {
                    tab.show = false;
                });
            }


        },
        async unlockRequestTask() {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            if (this.unlock_text) {
                formData.append('unlock_text', this.unlock_text)
            }
            if (this.change_deadline) {
                formData.append('change_deadline', this.change_deadline)
            }

            // Save
            await this.$http
                .put(`admin/task_request_unlock/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.extension_form) {
                        this.$toastr.success(this.$t('task_has_been_updated'))
                        this.extension_date = res.body.data.extension_form;
                        this.unlock_text = res.body.data.unlock_text;
                        this.extend_deadline_uuid = res.body.data.extend_deadline_uuid;
                        this.extension_form = true;
                        this.extension_button = false;
                        this.dialogUnlock = false;
                        this.change_deadline = null;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        async unlockTask() {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            if (this.extension_date) {
                formData.append('extension_date', this.extension_date)
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            // Save
            await this.$http
                .put(`admin/task_unlock/${this.extend_deadline_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.is_overdue = res.body.data.is_overdue;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        clearDeals() {
            if (!this.deal) {
                this.dealAddItems = []
            }
        },
        clearEmptyDealItems(items) {
            if (items && items.length > 0) {
                this.deal = items.filter(item => typeof item === 'object')
            }
        },
        showDeal(item) {
            this.$router.push({
                name: 'deal.show',
                params: {
                    id: item.uuid
                }
            })
        },
        showInput(field) {
            if (this.userBlock === false && this.closed  === false) {
                const show = 'show_' + field;
                if (this.$data[show]) {
                    this.$data[show] = false
                } else {
                    this.$data[show] = true;
                }
            }

        },
        saveField(field) {
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
            }
            this.saveTaskField(field, this.$data[field]);
        },
        changeField(field) {
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
            }
            if (this.$data[field] && this.$data[field].id) {
                this.saveTaskField(field, this.$data[field].id);
            } else {
                this.saveTaskField(field, this.$data[field]);
            }
            if(field === 'field_urgency_departure'){
                this.field_urgency_departure_text = this.urgencyDepartureItems
                    .filter(item => this.field_urgency_departure.includes(item.id))
                    .map(item => item.name)
                    .join(", ");
            }
        },
        async saveTaskField(field, value) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append(field, value)

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/task/${this.$route.params.id}/field`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }

        },
        updateDeadline(field, value) {
            this.displayDateTimePicker = false;
            this.saveTaskField(field, value);
        },
        updateDeadlineRequest() {
            this.displayRequestChangeDeadline = false;
            this.displayRequestChangeDeadlineText = false;
            this.saveRequestChangeDeadline();
        },
        openDisplayRequestChangeDeadlineText() {
            this.displayRequestChangeDeadline = false;
            if(this.deadline_request){
                this.displayRequestChangeDeadlineText = true;
            }
        },
        closeDisplayRequestChangeDeadlineText() {
            this.displayRequestChangeDeadline = false;
            this.displayRequestChangeDeadlineText = false;
            this.deadline_request = null;
            this.requestChangeDeadlineText = null;
        },
        async getRequestChangeDeadline() {
            this.loading = true;
            let params = {};

            await this.$http
                .get(`admin/deadline/request_change/${this.task_id}`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.id) {
                        this.request_change_deadline = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_tabs'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveRequestChangeDeadline() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('date', this.deadline_request)
            formData.append('reason', this.requestChangeDeadlineText)
            formData.append('type_id', this.task_id)

            await this.$http
                .post(`admin/deadline/request_change`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.request_change_deadline = res.body.data;
                    this.closeDisplayRequestChangeDeadlineText();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async acceptedRequestChangeDeadline(accepted) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('accepted', accepted ? 1 : 0)
            formData.append('type_id', this.request_change_deadline.type_id)

            await this.$http
                .put(`admin/deadline/request_change/${this.request_change_deadline.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.closeDisplayRequestChangeDeadlineText();
                    if(accepted){
                        this.deadline = this.request_change_deadline.date_deadline;
                    }
                    this.request_change_deadline = {}
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

        async favoriteUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            formData.append('type', 'task')

            await this.$http
                .put(`admin/favorite/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('favorite_has_been_updated'))
                    if (this.favorite) {
                        this.favorite = false;
                    } else {
                        this.favorite = true
                    }
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('favorite_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        goToUved(uved_show) {
            this.$router.push({
                name: 'uved.show',
                params: {
                    id: uved_show.uuid
                }
            })
        },
        clearUveds() {
            if (!this.uved) {
                this.uvedItems = []
            }
        },
        redirectToUved(val) {
            if (val) {
                this.$router.push({
                    name: 'uved.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getUveds(str) {
            if (str) {
                this.loadingUveds = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.uved = str
                }
                await this.$http
                    .get("admin/uved", {
                        params: params,
                    })
                    .then(res => {
                        this.uvedItems = res.body.data
                    })
                    .catch(err => {
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                        this.$nextTick(() => {
                            if (this.$refs.uved) {
                                this.$refs.uved.$el.querySelector('input').focus();
                            }
                        });
                    })
            }
        },
        async editParticipant() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }

            formData.append('send_message', 1)

            if (this.responsible && this.responsible[0] && this.responsible[0].id) {
                formData.append(`responsibles[0]`, this.responsible[0].id)
            }


            // Add
            await this.$http
                .put('admin/task_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.dialogAddParticipant = false;
                    this.responsible = this.responsibleAdd;

                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },


        async editParticipants() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('send_message', 1)

            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }

            if (this.responsibleAdd && this.responsibleAdd.length > 0) {
                for (let i in this.responsibleAdd) {
                    if (this.responsibleAdd[i].id !== undefined && this.responsibleAdd[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsibleAdd[i].id)
                    }
                }
            }
            if (this.auditorsAdd && this.auditorsAdd.length > 0) {
                for (let i in this.auditorsAdd) {
                    if (this.auditorsAdd[i].id !== undefined && this.auditorsAdd[i].id > 0) {
                        formData.append(`auditors[${i}]`, this.auditorsAdd[i].id)
                    }
                }
            }
            if (this.executorsAdd && this.executorsAdd.length > 0) {
                for (let i in this.executorsAdd) {
                    if (this.executorsAdd[i].id !== undefined && this.executorsAdd[i].id > 0) {
                        formData.append(`executors[${i}]`, this.executorsAdd[i].id)
                    }
                }
            }
            if (this.alignersAdd && this.alignersAdd.length > 0) {
                for (let i in this.alignersAdd) {
                    if (this.alignersAdd[i].id !== undefined && this.alignersAdd[i].id > 0) {
                        formData.append(`aligners[${i}]`, this.alignersAdd[i].id)
                    }
                }
            }

            if (this.uved) {
                if (this.uved.id) {
                    formData.append('uved', this.uved.id)
                } else {
                    formData.append('uved', this.uved)
                }
            }


            // Add
            await this.$http
                .put('admin/task_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.dialogAddParticipants = false;
                    if (typeof this.set_uved.id === "undefined") {
                        this.uved_show = this.set_uved;
                    }
                    this.responsible = this.responsibleAdd;
                    this.auditors = this.auditorsAdd;
                    this.executors = this.executorsAdd;
                    this.alignerAllItems = this.alignersAdd;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async addTaskToDeal() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.deal && this.deal.length > 0) {
                for (let i in this.deal) {
                    if (this.deal[i].id !== undefined && this.deal[i].id > 0) {
                        formData.append(`deals[${i}]`, this.deal[i].id)
                    }
                }
            }

            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            // Add
            await this.$http
                .post('admin/task_deal', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                    this.dialogLinkDeal = false;
                    this.getDeals();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async checkCreate() {
            if (this.$route.name === "task.create") {
                this.heading = this.$t('task_creation')
            } else {
                this.heading = this.$t('task_editing')
                if (this.$route.params.id) {
                    await this.getTypeTransport()
                    await this.getUrgencyDeparture()
                    await this.getTask()
                    if (this.edit_task_type === 3) {
                        if (this.loadingTypeItems.length <= 0) {
                            await this.getLoadingType()
                        }
                        if (this.typeTransportItems.length <= 0) {
                            await this.getTypeTransport()
                        }
                        if (this.urgencyDepartureItems.length <= 0) {
                            await this.getUrgencyDeparture()
                        }
                    }
                }
            }
        },
        async getLoadingType() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/loading_type", {
                    params: params,
                })
                .then(res => {
                    this.loadingTypeItems = res.body.data
                })
                .catch(err => {
                    this.loadingTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getTypeTransport() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/type_transport", {
                    params: params,
                })
                .then(res => {
                    this.typeTransportItems = res.body.data
                    this.field_type_transportItems = res.body.data
                })
                .catch(err => {
                    this.typeTransportItems = []
                    this.field_type_transportItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getUrgencyDeparture() {

            this.loading = true
            let params = {}
            await this.$http
                .get("admin/urgency_departure", {
                    params: params,
                })
                .then(res => {
                    this.urgencyDepartureItems = res.body.data
                    this.field_urgency_departureItems = res.body.data
                })
                .catch(err => {
                    this.urgencyDepartureItems = []
                    this.field_urgency_departureItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },

        getCalendarCase() {
            let id_array = 0;
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                id_array = 1
            } else {
                id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1
            }
            this.$refs.calendarCase.getCaseAdmin('task', id_array);

        },
        handleClick(click, msg, date) {
            switch (click) {
                case 'delete':
                    this.deleteMessages(msg, date)
                    break;
                case 'reply' :
                    this.replyOpen(msg)
                    break;
            }
        },
        replyOpen(msg) {
            this.reply_uuid = msg.uuid
            this.reply_msg = msg.comment;
            this.reply_name = msg.name;
            this.toolbar_reply = true
        },
        replyClose() {
            this.scrollCard()
            this.reply_uuid = null
            this.reply_name = null
            this.toolbar_reply = false
            this.reply_msg = {}
        },
        scrollCard() {
            this.$nextTick(() => {
                // Прокрутка до нижней части после обновления списка
                if (this.$refs.scrollContainer !== undefined) {
                    const container = this.$refs.scrollContainer.$el;
                    const firstUnread = this.$refs.scrollContainer.$el.querySelector(".chat-message--new");

                    if (firstUnread) {
                        const containerRect = container.getBoundingClientRect();
                        const elementRect = firstUnread.getBoundingClientRect();
                        const offset = elementRect.top - containerRect.top + container.scrollTop;

                        container.scrollTo({
                            top: offset,
                            behavior: "smooth",
                        });
                    } else {
                        container.scrollTop = container.scrollHeight;
                    }
                }
            });
        },
        clearCompanies() {
            if (!this.company) {
                this.companyItems = []
            }
        },
        redirectToCompany(val) {
            if (val) {
                this.$router.push({
                    name: 'company.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data
                    })
                    .catch(err => {
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })
            }
        },
        async getTaskTypes(str) {
            if (str) {
                this.loadingTaskTypes = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.task_type = str
                }
                await this.$http
                    .get("admin/task_type", {
                        params: params,
                    })
                    .then(res => {
                        this.taskTypeItems = res.body.data
                    })
                    .catch(err => {
                        this.taskTypeItems = []
                    })
                    .finally(end => {
                        this.loadingTaskTypes = false
                    })
            }
        },
        clearTaskTypes() {
            if (!this.task_type) {
                this.taskTypeItems = []
            }
        },
        clearResponsibles() {
            if (!this.responsibleAdd) {
                this.responsibleItems = []
            }
        },
        clearEmptyResponsibleItems(items) {
            if (items && items.length > 0) {
                this.responsibleAdd = items.filter(item => typeof item === 'object')
            }
        },
        async getResponsibles(str) {
            if (str) {
                this.loadingResponsibles = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.responsibleItems = res.body.data
                    })
                    .catch(err => {
                        this.responsibleItems = []
                    })
                    .finally(end => {
                        this.loadingResponsibles = false
                    })
            }
        },
        clearAligners() {
            if (!this.alignersAdd) {
                this.alignerItems = []
            }
        },
        clearEmptyAlignerItems(items) {
            if (items && items.length > 0) {
                this.alignersAdd = items.filter(item => typeof item === 'object')
            }
        },
        async getAligners(str) {
            if (str) {
                this.loadingAligners = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.alignerItems = res.body.data
                    })
                    .catch(err => {
                        this.alignerItems = []
                    })
                    .finally(end => {
                        this.loadingAligners = false
                    })
            }
        },
        clearAuditors() {
            if (!this.auditorsAdd) {
                this.auditorItems = []
            }
        },
        clearEmptyAuditorItems(items) {
            if (items && items.length > 0) {
                this.auditorsAdd = items.filter(item => typeof item === 'object')
            }
        },
        async getAuditors(str) {
            if (str) {
                this.loadingAuditors = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.auditorItems = res.body.data
                    })
                    .catch(err => {
                        this.auditorItems = []
                    })
                    .finally(end => {
                        this.loadingAuditors = false
                    })
            }
        },
        async getDeals() {
            this.loadingDeal = true;
            let params = {};
            params.sortBy = 'id';
            params.sortDir = 'desc';
            params.deal_status = -2

            params.task_uuid = this.task_uuid;

            await this.$http
                .get("admin/task_deal/" + this.task_uuid, {
                    params: params,
                })
                .then(res => {
                    this.dealItems = res.body.data
                })
                .catch(err => {
                    this.dealItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_deals'))
                })
                .finally(end => {
                    this.loadingDeal = false
                })
        },
        async getAddDeals(str) {
            if (str) {
                this.loadingDeals = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.deal = str
                }
                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealAddItems = res.body.data
                    })
                    .catch(err => {
                        this.dealAddItems = []
                    })
                    .finally(end => {
                        this.loadingDeals = false
                    })
            }
        },
        async getSubtasks() {
            this.loadingSubtask = true;
            const {
                sortBy,
                sortDesc,
                page
            } = this.options;
            let params = {};
            if (sortBy !== undefined && sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            } else {
                params.sortBy = 'id';
            }
            if (sortDesc !== undefined && sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            } else {
                params.sortDir = 'desc';
            }
            if (page !== undefined) {
                params.page = page
            } else {
                params.page = 1
            }


            params.task_uuid = this.task_uuid;

            await this.$http
                .get("admin/subtasks", {
                    params: params,
                })
                .then(res => {
                    this.subtaskItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalSubTasks = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.subtaskItems = []
                    this.totalSubTasks = 0
                    this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                })
                .finally(end => {
                    this.loadingSubtask = false
                })
        },
        showTask(item) {
            //  window.location.href = '/task/'+item.uuid+'/show';
            this.$router.push({
                name: 'subtask.show',
                params: {
                    id: item.uuid
                },
            })
            this.forceRerender()
        },

        linkSubTask() {
            this.dialogLinkTask = true;
        },
        async addTaskToSubTask() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.task && this.task.length > 0) {
                for (let i in this.task) {
                    if (this.task[i].id !== undefined && this.task[i].id > 0) {
                        formData.append(`tasks[${i}]`, this.task[i].id)
                    }
                }
            }

            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }
            // Add
            await this.$http
                .post('admin/task_subtask', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.dialogLinkTask = false;
                    this.getSubtasks();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        clearTasks() {
            if (!this.task) {
                this.taskAddItems = []
            }
        },
        async getTasks(str) {
            if (str) {
                this.loadingTasks = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.task = str
                }
                await this.$http
                    .get("admin/task", {
                        params: params,
                    })
                    .then(res => {
                        this.taskAddItems = res.body.data
                    })
                    .catch(err => {
                        this.taskAddItems = []
                    })
                    .finally(end => {
                        this.loadingTasks = false
                    })
            }
        },
        clearEmptyTaskItems(items) {
            if (items && items.length > 0) {
                this.task = items.filter(item => typeof item === 'object')
            }
        },
        addSubTask() {
            this.$router.push({
                name: 'subtask.create',
                params: {
                    id: this.$route.params.id
                }
            })
        },
        addDeal() {
            this.$router.push({
                name: 'deal.create.task',
                params: {
                    task_uuid: this.$route.params.id
                }
            })
        },
        linkDeal() {
            this.dialogLinkDeal = true;
        },
        ImageViewClose() {
            this.dialog_image_view = false;
            this.image_view = {};
        },
        ImageView(msg) {
            this.dialog_image_view = true;
            this.image_view = msg;
        },
        async getTaskStatuses() {
            this.loadingTaskStatus = true
            let params = {}
            await this.$http
                .get("admin/task_status", {
                    params: params,
                })
                .then(res => {
                    this.task_statusItems = res.body.data
                })
                .catch(err => {
                    this.task_statusItems = []
                })
                .finally(end => {
                    this.loadingTaskStatus = false
                })
        },
        async getTask() {
            var _this = this;
            this.progress = 0
            this.loadingTask = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/task/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.task_id = res.body.data.id
                    this.closed = res.body.data.closed
                    this.task_uuid = res.body.data.uuid
                    this.all_agreed = res.body.data.all_agreed
                    this.task_score_admin = res.body.data.task_score_admin
                    this.edit_task_type = res.body.data.task_type.id
                    this.completion_date = res.body.data.completion_date
                    this.day_work = res.body.data.day_work
                    this.deadline = res.body.data.deadline
                    this.start_at = res.body.data.start_at
                    this.executor_task_status_id = (res.body.data && res.body.data.executor_info && res.body.data.executor_info.task_status_id) ? res.body.data.executor_info.task_status_id : 0
                    this.executor_text = (res.body.data && res.body.data.executor_info && res.body.data.executor_info.text )? res.body.data.executor_info.text : null
                    this.executor_date_approval = (res.body.data && res.body.data.executor_info && res.body.data.executor_info.date_approval) ? res.body.data.executor_info.date_approval : null


                    this.favorite = res.body.data.favorite
                    this.number = res.body.data.number
                    this.author = res.body.data.author
                    this.uved_show = res.body.data.uved
                    this.set_show = res.body.data.uved
                    this.uved = res.body.data.uved.id
                    this.uvedItems = [res.body.data.uved]
                    this.text_task = res.body.data.text
                    this.task_status = res.body.data.task_status.id
                    this.status_color = res.body.data.task_status.color
                    this.status_name = res.body.data.task_status.name
                    this.responsible = res.body.data.responsibles
                    this.responsibleAdd = res.body.data.responsibles
                    this.auditors = res.body.data.auditors
                    this.auditorsAdd = res.body.data.auditors
                    this.executors = res.body.data.executors
                    this.executorsAdd = res.body.data.executors
                    this.auditor = res.body.data.auditors
                    this.alignersAdd = res.body.data.aligners_edit
                    this.alignerItems = res.body.data.aligners
                    this.alignerAllItems = res.body.data.aligners_edit
                    this.company = res.body.data.company
                    this.companyItems = [res.body.data.company]
                    this.accompanying_document_urls = res.body.data.documents
                    this.task_documents = res.body.data.task_documents
                    this.created_at = res.body.data.created_at
                    this.date_cargo_readiness = res.body.data.date_cargo_readiness
                    this.heading = this.number + '. ' + res.body.data.title
                    this.title = res.body.data.title
                    this.not_accepted = res.body.data.not_accepted
                    this.accepted = res.body.data.accepted
                    this.rejected = res.body.data.rejected
                    this.is_aligner = res.body.data.is_aligner
                    this.is_responsible = res.body.data.is_responsible
                    this.is_executor = res.body.data.is_executor
                    this.is_author = res.body.data.is_author
                    this.is_agreed = res.body.data.is_agreed
                    this.is_urgent = res.body.data.is_urgent
                    this.is_auditor = res.body.data.is_auditor
                    this.is_overdue = res.body.data.is_overdue
                    this.loading_type = res.body.data.task_bet_field.loading_type
                    this.type_transport = res.body.data.task_bet_field.type_transport
                    this.urgency_departure = res.body.data.task_bet_field.urgency_departure
                    this.from_city = res.body.data.task_bet_field.from_city
                    this.to_city = res.body.data.task_bet_field.to_city
                    this.special_text = res.body.data.task_bet_field.special_text
                    this.dangerous_cargo = res.body.data.task_bet_field.dangerous_cargo
                    this.stackable_cargo = res.body.data.task_bet_field.stackable_cargo

                    this.extend_deadline_uuid = res.body.data.extend_deadline.uuid;
                    this.unlock_text = res.body.data.extend_deadline.comment;
                    this.extension_date = res.body.data.extend_deadline.request_extension;
                    this.extension_form = (this.extension_date) ? true : false;
                    this.extension_button = (!this.extension_date) ? true : false;

                    this.weight = res.body.data.task_bet_field.weight
                    this.volume = res.body.data.task_bet_field.volume
                    this.terms_delivery = res.body.data.task_bet_field.terms_delivery
                    this.place_argo_pickup = res.body.data.task_bet_field.place_argo_pickup
                    this.goods = res.body.data.task_bet_field.goods
                    this.insurance = res.body.data.task_bet_field.insurance
                    this.field_type_transport = res.body.data.type_transport;
                    this.field_type_transport_text = res.body.data.type_transport_text;
                    this.field_urgency_departure = res.body.data.urgency_departure;
                    if (this.urgencyDepartureItems && this.urgency_departure && Array.isArray(this.urgencyDepartureItems) && Array.isArray(this.urgency_departure)) {
                        this.field_urgency_departure_text = this.urgencyDepartureItems
                            .filter(item => this.urgency_departure.includes(item.id))
                            .map(item => item.name)
                            .join(", ");
                    } else {
                        // Если один из массивов пуст или не существует, можно установить значение по умолчанию
                        this.field_urgency_departure_text = "";
                    }
                    this.checkTermsDelivery();

                })
                .catch(err => {
                    this.$router.push({name: 'error-404'});
                    this.$toastr.error(this.$t('failed_to_get_task'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingTask = false
                })
        },
        async TaskTextUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.text_task) {
                formData.append('text', this.text_task)
            }

            if (this.$route.params.id && this.task_uuid) {
                await this.$http
                    .put(`admin/task_text/${this.task_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_updated'))
                        this.text_task_edit = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        downloadFile(data) {
            fetch(data.url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
        downloadFileMessage(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },
        dropFile(event) {
            this.files = Array.from(event.dataTransfer.files);
            this.previewFiles();
        },
        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },
        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },
        deleteAccompanyingDocumentFile(item) {
            let index = this.accompanying_document_urls.indexOf(item)
            this.accompanying_document_urls.splice(index, 1)
        },
        deleteAccompanyingDocuments(item) {
            let index = this.accompanying_documents_preview.indexOf(item)
            this.accompanying_documents_preview.splice(index, 1)
            if (this.accompanying_documents_preview.length === 0) {
                this.accompanying_documents_dialog = false;
                this.accompanying_documents_preview = [];
                this.accompanying_documents = []
            }
        },
        async saveTaskFile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.edit_task_type) {
                formData.append('task_type', this.edit_task_type)
            }

            if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                let is_image = false;
                for (let i in this.accompanying_documents_preview) {
                    is_image = this.mimeTypeImage(this.accompanying_documents_preview[i].type);
                    formData.append(`type_mime[${i}]`, this.accompanying_documents_preview[i].type)
                    formData.append(`size[${i}]`, this.accompanying_documents_preview[i].size)
                    formData.append(`file_name[${i}]`, this.accompanying_documents_preview[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    this.accompanying_documents_preview[i].blob = await this.convertBlobToBase64(this.accompanying_documents_preview[i].blob)
                    if (this.accompanying_documents_preview[i].blob) {
                        let blob = this.dataURL64toBlob(this.accompanying_documents_preview[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.accompanying_documents_preview[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                }
            }

            await this.$http
                .put(`admin/task/file/${this.task_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))

                    if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                        for (let i in this.accompanying_documents_preview) {
                            let author = {
                                "author": {
                                    "name": this.$auth.user().name
                                }
                            };
                            Object.assign(this.accompanying_documents_preview[i], author);
                            this.accompanying_document_urls.push(this.accompanying_documents_preview[i])
                        }
                        this.accompanying_documents_dialog = false;
                        this.accompanying_documents_preview = [];
                        this.accompanying_documents = []
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async deleteFileServer(item) {
            if (confirm(this.$t('want_to_delete_file'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/task/file/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('file_has_been_deleted'))
                        this.deleteAccompanyingDocumentFile(item)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        TaskMessagesWebsocket() {
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen(`.Event.TaskChat.${this.task_uuid}`, (event) => {
                    //Если пришло добавление собщение
                    if (event && event.id && event.id > 0) {
                        if (event.comment && event.comment.type) {
                            // if(event.comment.type === 'text') {

                            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                                const newMessage = {
                                    [this.$moment().format('YYYY-MM-DD')]:
                                        [
                                            event
                                        ]
                                }
                                this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                            } else {
                                let message_date = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')];
                                let uuidToFind = event.uuid;
                                let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                                if (typeof foundObject != "undefined") {
                                    foundObject.id = event.id;
                                    foundObject.comment = event.comment;
                                    foundObject.delivered = event.delivered;
                                    foundObject.uuid = event.uuid;
                                    foundObject.created_at = event.created_at;
                                } else {
                                    message_date.push(event);
                                }
                            }
                        }

                    } else if (event && event.type && event.type === 'delete') {
                        //Если пришло удаление собщение
                        if (event.id_array && event.id_array > 0 && event.date) {
                            //if(event.admin_id !== this.$auth.user().id){
                            this.chatMessagesDate[event.date].splice(event.id_array, 1);
                            // }

                        }
                    }
                    this.scrollCard();

                });
        },

        accompanyingDocumentsPreviewFiles() {
            let i = 0;
            let data = {}
            this.accompanying_documents.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.accompanying_documents_preview.push(data)
                i++
            })
            this.accompanying_documents_dialog = true
        },
        openStatusDeadline(status) {
            this.extend_deadline = this.deadline
            this.status_deadline = status
            this.displayDateTimePickerExtendDeadline = true;


        },
        updateStatusDeadline() {
            this.displayDateTimePickerExtendDeadline = false;
            if(this.extend_deadline !== this.deadline){
                this.editStatus(this.status_deadline)
            }
        },
        getStatusName(task_status_id){
            let status_text = '';
            switch (task_status_id){
                case 0: status_text = this.$t('didnt_accept_it'); break;
                case 2: status_text = this.$t('took_over_job'); break;
                case 5: status_text = this.$t('cancel'); break;
                case 6: status_text = this.$t('completed'); break;
            }
            return status_text
        },
        cancelDialogStatusExecutor(status) {
            this.extend_deadline = this.deadline
            this.status_executor = status
            this.displayCancelStatusExecutor = true;
        },
        cancelStatusExecutor() {
            this.editStatusExecutor(this.status_executor);
            this.displayCancelStatusExecutor = false;
            this.status_executor = null;
            this.status_executor_text = null;
        },
        closeDialogStatusExecutor() {
            this.displayCancelStatusExecutor = false;
            this.status_executor = null;
            this.status_executor_text = null;
        },
        async editStatusExecutor(status) {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }

            if (this.status_executor_text) {
                formData.append('text', this.status_executor_text)
            }
            if(status === 'reject' ){
                status = 'cancel'
            }
            formData.append('task_status', status)

            if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            }
            else{
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }


            await this.$http
                .put(`admin/task/status_executor/${this.task_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.executor_task_status_id = res.body.data.task_status_id
                    this.getTask();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async editStatus(status) {


            let err = 0;

            if(status === 'approved' && this.edit_task_type === 3){

                if(this.loading_type === null || (typeof this.loading_type.id === 'undefined' ||  this.loading_type.id === null || this.loading_type.id === '')){
                    err = 1
                    this.error_loading_type = 'text_red'
                }
                else{
                    this.error_loading_type = ''
                }

                if(typeof this.from_city === 'undefined' ||  this.from_city === null || this.from_city === ''){
                    err = 1
                    this.error_from_city = 'text_red'
                }
                else{
                    this.error_from_city = ''
                }
                if(typeof this.to_city === 'undefined' ||  this.to_city === null || this.to_city === ''){
                    err = 1
                    this.error_to_city = 'text_red'
                }
                else{
                    this.error_to_city = ''
                }

                if(err === 1){
                    this.$toastr.error(this.$t('fill_in_field'))
                    return;
                }


            }

            else if(status === 'cancel' && this.edit_task_type === 3){
                if(this.loading_type === null || (typeof this.loading_type.id === 'undefined' ||  this.loading_type.id === null || this.loading_type.id === '')){
                    err = 1
                    this.error_loading_type = 'text_red'
                }
                else{
                    this.error_loading_type = ''
                }
                if(typeof this.from_city === 'undefined' ||  this.from_city === null || this.from_city === ''){
                    err = 1
                    this.error_from_city = 'text_red'
                }
                else{
                    this.error_from_city = ''
                }
                if(typeof this.to_city === 'undefined' ||  this.to_city === null || this.to_city === ''){
                    err = 1
                    this.error_to_city = 'text_red'
                }
                else{
                    this.error_to_city = ''
                }

                if(err === 1){
                    this.$toastr.error(this.$t('fill_in_field'))
                    return;
                }
            }


            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }
            if(this.edit_task_type){
                formData.append('task_type',  this.edit_task_type)
            }

            if(this.extend_deadline){
                formData.append('deadline',  this.extend_deadline)
            }



            formData.append('task_status', status)

            if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            }
            else{
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }


            await this.$http
                .put(`admin/task/status/${this.task_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.task_status = res.body.data.task_status_id
                    this.status_color = res.body.data.task_status.color
                    this.status_name = res.body.data.task_status.name
                    if(typeof res.body.data.deal_uuid !== 'undefined' &&  res.body.data.deal_uuid !== null){
                        this.$router.push({
                            name: 'deal.show',
                            params: {
                                id: res.body.data.deal_uuid
                            }
                        })
                    }
                    if(this.extend_deadline){
                        this.deadline = this.extend_deadline;
                    }
                    this.extend_deadline = null
                    this.status_deadline = null
                    this.task_score_admin = res.body.data.task_score_admin
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async addStatusApproval(status) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }
            formData.append('task_status', status)
            if (this.reject_text) {
                formData.append('reject_text', this.reject_text)
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            await this.$http
                .post(`admin/task/status/${this.task_uuid}/approval`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.getTask();
                    this.is_agreed = res.body.data.is_agreed
                    this.dialogStatusApproval = false;
                    this.reject_text = null;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async addStatusApprovalFile(status) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }
            formData.append('file_status', status)
            if (this.reject_text) {
                formData.append('reject_text', this.reject_text)
            }

            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            await this.$http
                .post(`admin/task/status/file/${this.task_document_id}/approval`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.getTask();
                    this.task_document_id = null
                    this.dialogStatusApprovalFile = false;
                    this.reject_text = null;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        showCompany(uuid) {
            this.$router.push({
                name: 'company.show',
                params: {
                    id: uuid
                }
            })
        },
        openDialogDate() {

            this.displayDateTimePicker = this.task_status == 1 ? true : false;
        },
        requestChangeDeadline() {
            this.displayRequestChangeDeadline = true;
        },
        closeDialogDateTimePicker() {
            this.displayDateTimePicker = false;
        },

        async delegate(users) {
            // выбранных пользователей ставим в ответственных
            this.$http.put(`admin/task/${this.task_uuid}`, {
                responsible: users.map((id) => {
                    return {
                        id
                    }
                })
            }).then(res => {
                this.$toastr.success(this.$t('task_has_been_updated'))
                this.getTask();
            }).catch(err => {
                this.$toastr.error(this.$t('task_has_not_been_updated'))
            })
            // ответвенного ставим в наблюдатели
        },

        onDelegate() {
            var formData = new FormData()

            // текущего пользователя убрать из ответственных
            let responsibles = this.responsible.filter((item) => {
                return item.id !== this.$auth.user().id
            }).map((item) => {
                return item.id
            })

            // добавляем делегируемого пользователя в ответственного
            this.delegatingToUsers.map(item => {
                if(!responsibles.includes(item)) {
                    responsibles.push(item);
                }
            })

            let i = 0;
            responsibles.map(item => {
                formData.append(`responsibles[${i}]`, item.id);
                i++;
            })

            // текущего пользователя в наблюдатели
            let auditors = this.auditors.map(item => {
                return item.id
            });

            if(!auditors.includes(this.$auth.user().id)) {
                auditors.push(this.$auth.user().id)
            }

            i = 0
            auditors.map(item => {
                formData.append(`auditors[${i}]`, item);
                i++;
            });

            // formData.append('responsibles', responsibles);
            // formData.append('auditors', auditors);
            formData.append('do_not_delete', 1);
            formData.append('task_uuid', this.$route.params.id)
            formData.append('send_messages', 1)
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            this.$http.put(`admin/task_participant`, formData).then(res => {
                this.$toastr.success(this.$t('task_has_been_updated'))
                this.getTask();
                this.dialogDelegate = false;
            }).catch(err => {
                this.$toastr.error(this.$t('task_has_not_been_updated'))
            })

        },

        markAsRead(message) {
            this.$http.post(`admin/task_message/${message.id}/reader`).then(res => {
                message.is_readed = true;
            }).catch(err => {
            });
        },
        async translation(text, comment_uuid = '') {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            formData.append('text', text)
            if (comment_uuid !== '') {
                formData.append('comment_uuid', comment_uuid)
            }
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('task_uuid', this.$route.params.id)
            }


            // Save
            await this.$http
                .post(`admin/translate_text`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res.body.data.task) {
                        this.title = res.body.data.task.title;
                        this.text_task = res.body.data.task.text;
                        if (res.body.data.task_bet_field) {
                            this.from_city = res.body.data.task_bet_field.from_city;
                            this.to_city = res.body.data.task_bet_field.to_city;
                            this.special_text = res.body.data.task_bet_field.special_text;
                            this.weight = res.body.data.task_bet_field.weight;
                            this.volume = res.body.data.task_bet_field.volume;
                            this.terms_delivery = res.body.data.task_bet_field.terms_delivery;
                            this.place_argo_pickup = res.body.data.task_bet_field.place_argo_pickup;
                            this.goods = res.body.data.task_bet_field.goods;
                        }
                    } else if (res.body.data.comment) {
                        let message_date = this.chatMessagesDate[this.$moment(res.body.data.comment.created_at).format('YYYY-MM-DD')];
                        let uuidToFind = res.body.data.comment.uuid;
                        let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                        if (typeof foundObject != "undefined") {
                            foundObject.comment = res.body.data.comment.comment;
                            foundObject.uuid = res.body.data.comment.uuid;
                        }
                    }

                    this.$toastr.success(this.$t('translated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('translation_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
    }
}
</script>

<style scoped>
.v-card--link:before {
    background: none;
}

>>> .heightTable td {
    height: 50px !important;
}

.select_border_none >>> .v-input__slot::before {
    border-style: none !important;
}

.request_change_deadline{
    background-color: #fef3c7;
}
</style>
<style lang="scss">
.approval-sheet__item {
    @media screen and(max-width: 992px) {
        &.v-list-item {
            flex-direction: column;
            min-height: auto;
            padding-left: 0;
            padding-right: 0;
        }

        .approval-sheet__desc {
            width: 100%;
        }

        .approval-sheet__btns {
            width: 100%;
            flex-direction: row;

            .v-btn {
                flex-basis: auto;
            }
        }
    }
}

.task-document {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter / 2;
    border: 1px solid #eeeeee;
    flex-wrap: wrap;

    &.bordered {
        border-radius: 8px;
        padding: $grid-gutter / 2;;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.task-document__desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: $grid-gutter / 2;
}

.task-document__icon {
    width: 45px;
    height: 45px;
    margin-right: $grid-gutter / 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-document__text {
    flex-grow: 1;
}

.task-document__approval {
    margin-top: $grid-gutter / 2;
    padding-top: $grid-gutter / 2;
    border-top: 1px solid #ccc;
}

.approval {
    width: 100%;

    &:empty {
        display: none;
    }
}

.approval__actions {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: $grid-gutter / 2;
    flex-direction: row-reverse;

    & > * {
        flex-grow: 1;
    }
}

.approval__info {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter / 2;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .task-document {
        flex-direction: row;
    }

    .task-document__approval-info {
        flex-direction: row;
        gap: $grid-gutter / 2;
    }

    .approval-actions {
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }

    .task-document__desc {
        margin-bottom: 0;
    }
}
</style>
